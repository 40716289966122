import React from 'react'

import { createStackNavigator } from '@react-navigation/stack'

import ForgotPasswordScreen from '../screens/forgot-password/ForgotPasswordScreen'
import LoginScreen from '../screens/login/LoginScreen'
import RegisterScreen from '../screens/register/RegisterScreen'
import WelcomeScreen from '../screens/welcome/WelcomeScreen'
import { UnauthorizedStackParamsType } from '../types/navigation-types'

const UnauthorizedStack = createStackNavigator<UnauthorizedStackParamsType>()

export default (): JSX.Element => {
  return (
    <UnauthorizedStack.Navigator
      initialRouteName="Welcome"
      screenOptions={{ headerShown: false }}>
      <UnauthorizedStack.Screen
        name="Welcome"
        component={WelcomeScreen}
        options={{ title: 'Sākums' }}
      />
      <UnauthorizedStack.Screen
        name="Register"
        component={RegisterScreen}
        options={{ title: 'Reģistrēties' }}
      />
      <UnauthorizedStack.Screen
        name="Login"
        component={LoginScreen}
        options={{ title: 'Ienākt' }}
      />
      <UnauthorizedStack.Screen
        name="ForgotPassword"
        component={ForgotPasswordScreen}
        options={{ title: 'Atjaunot paroli' }}
      />
    </UnauthorizedStack.Navigator>
  )
}
