import React, { useMemo } from 'react'

import { SvgProps } from 'react-native-svg'

import BurgerSvg from '../assets/svg/burger.svg'
import CheckSvg from '../assets/svg/check.svg'
import ChevronLeftSvg from '../assets/svg/chevron-left.svg'
import EditSvg from '../assets/svg/edit.svg'
import EyeSvg from '../assets/svg/eye.svg'
import FilterSvg from '../assets/svg/filter.svg'
import LogoutSvg from '../assets/svg/logout.svg'
import PersonSvg from '../assets/svg/person.svg'
import PlusSvg from '../assets/svg/plus.svg'
import ProfileSvg from '../assets/svg/profile.svg'
import SaveSvg from '../assets/svg/save.svg'
import SearchSvg from '../assets/svg/search.svg'
import X from '../assets/svg/x.svg'

export type SvgName =
  | 'eye'
  | 'chevron-left'
  | 'burger'
  | 'check'
  | 'search'
  | 'filter'
  | 'profile'
  | 'plus'
  | 'edit'
  | 'save'
  | 'x'
  | 'person'
  | 'logout'

export const SvgIcon: React.FC<SvgProps & { name: SvgName }> = ({
  name,
  ...props
}) => {
  const Component = useMemo(() => {
    switch (name) {
      case 'eye':
        return EyeSvg
      case 'chevron-left':
        return ChevronLeftSvg
      case 'burger':
        return BurgerSvg
      case 'search':
        return SearchSvg
      case 'filter':
        return FilterSvg
      case 'check':
        return CheckSvg
      case 'profile':
        return ProfileSvg
      case 'plus':
        return PlusSvg
      case 'edit':
        return EditSvg
      case 'save':
        return SaveSvg
      case 'x':
        return X
      case 'person':
        return PersonSvg
      case 'logout':
        return LogoutSvg
    }
  }, [name])

  const defaultColor = useMemo(() => {
    switch (name) {
      default:
        return '#F0F'
    }
  }, [name])

  const viewBox = useMemo(() => {
    switch (name) {
      case 'eye':
        return '0 0 20 15'
      case 'chevron-left':
        return '0 0 15 15'
      case 'burger':
        return '0 0 15 15'
      case 'check':
        return '0 0 15 15'
      case 'profile':
        return '0 0 16 16'
      case 'plus':
      case 'edit':
        return '0 0 16 16'
      case 'save':
        return '0 0 16 16'
      case 'x':
        return '0 0 24 24'
      case 'person':
        return '0 0 15 15'
      case 'logout':
        return '0 0 15 15'
    }
  }, [name])

  return <Component color={defaultColor} viewBox={viewBox} {...props} />
}
