import React, { useCallback, useState } from 'react'
import { View, Platform, KeyboardAvoidingView, StyleSheet } from 'react-native'

import { useNavigation } from '@react-navigation/native'
import { Asset } from 'react-native-image-picker'

import { MainForm, ProfileCityForm, ProfileUniversityForm } from './screens'
import {
  showAlert,
  ScreenWrapper,
  showDestructiveAlert,
} from '../../components'
import { usePicturePicker } from '../../hooks/usePicturePicker'
import useSafeAreaPaddedStyle, {
  headerOptions,
} from '../../hooks/useSafeAreaPaddedStyle'
import { useTerbata } from '../../providers'
import { useFirebase } from '../../providers/FirebaseProvider'
import appStyles from '../../styles/app-styles'
import { ProfileEditForm, UserCity, UserUniversity } from '../../types'
import { MainStackNavigationType } from '../../types/navigation-types'
import { UserData } from '../../types/user-types'

export default (): JSX.Element => {
  const { goBack } = useNavigation<MainStackNavigationType<'ProfileEdit'>>()
  const {
    userData,
    deleteUser,
    saveUserData,
    uploadPicture,
    deletePicture,
    saveSelectedCities,
    saveSelectedUniversities,
  } = useFirebase()

  const { profileEditFormState } = useTerbata()

  const [name, setName] = useState(userData?.name)
  const [surname, setSurname] = useState(userData?.surname)
  const [cities, setCities] = useState(userData?.cities ?? [])
  const [universities, setUniversities] = useState(userData?.universities ?? [])
  const [description, setDescription] = useState(userData?.description)
  const [phoneNumber, setPhoneNumber] = useState(userData?.phoneNumber)
  const [showEmail, setShowEmail] = useState(userData?.showEmail ?? false)

  const [picture, showPictureOptions] = usePicturePicker(
    userData?.photoUrl ?? undefined,
  )

  const checkHasUnsavedChanges = useCallback(() => {
    return (
      name !== userData?.name ||
      surname !== userData?.surname ||
      description !== userData?.description ||
      cities !== userData?.cities ||
      cities.length !== userData?.cities.length ||
      universities !== userData?.universities ||
      universities.length !== userData?.universities.length ||
      showEmail !== userData?.showEmail ||
      phoneNumber !== userData?.phoneNumber ||
      (picture ?? null) !== userData?.photoUrl
    )
  }, [
    name,
    userData,
    surname,
    description,
    cities,
    universities,
    showEmail,
    phoneNumber,
    picture,
  ])

  const onGoBack = useCallback(() => {
    checkHasUnsavedChanges()
      ? showDestructiveAlert(
          'Vai tiešām nevēlies saglabāt izmaiņas?',
          'Visas Jūsu ievadītās izmaiņas tiks zaudētas.',
          goBack,
          'Nesaglabāt',
        )
      : goBack()
  }, [checkHasUnsavedChanges, goBack])

  const onSave = () =>
    (typeof picture === 'object' && picture !== null
      ? uploadPicture({ picture: picture as Asset })
      : Promise.resolve(picture as string)
    )
      .then(photoUrl => {
        if (photoUrl === undefined) {
          return deletePicture().then(() => Promise.resolve(null))
        }
        return Promise.resolve(photoUrl)
      })
      .then(photoUrl => {
        const formData: UserData = {
          isFilled: true,
          name,
          surname,
          cities,
          universities,
          description,
          phoneNumber,
          photoUrl,
          showEmail,
        }
        saveUserData({
          newUserData: formData,
        })
      })
      .then(() => saveSelectedCities({ newCities: cities }))
      .then(() =>
        saveSelectedUniversities({
          // @ts-ignore
          newUniversities: universities.map(uni => ({
            Country: uni.country,
            University: uni.university,
            Website: null,
          })),
        }),
      )
      .then(goBack)
      .catch(() => {
        showAlert('Neizdevās saglabāt lietotāja datus')
      })

  const onAddCity = (city: UserCity) => {
    setCities([...cities, city])
  }

  const onAddUniversity = (university: UserUniversity) => {
    setUniversities([...universities, university])
  }

  const onDeleteUser = () => {
    deleteUser()
  }

  const forms: Record<ProfileEditForm, React.JSX.Element> = {
    [ProfileEditForm.Main]: (
      <MainForm
        name={name}
        surname={surname}
        cities={cities}
        universities={universities}
        description={description}
        phoneNumber={phoneNumber}
        email={userData?.email ?? ''}
        showEmail={showEmail}
        picture={picture}
        setName={setName}
        setSurname={setSurname}
        setCities={setCities}
        setUniversities={setUniversities}
        setDescription={setDescription}
        setPhoneNumber={setPhoneNumber}
        setShowEmail={setShowEmail}
        showPictureOptions={showPictureOptions}
        onSave={onSave}
        onGoBack={onGoBack}
        onDeleteUser={onDeleteUser}
      />
    ),
    [ProfileEditForm.CityForm]: <ProfileCityForm onAdd={onAddCity} />,
    [ProfileEditForm.UniversityForm]: (
      <ProfileUniversityForm onAdd={onAddUniversity} />
    ),
  }

  const getForm = () => forms[profileEditFormState]

  return (
    <ScreenWrapper>
      <View style={useSafeAreaPaddedStyle(headerOptions)}>
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          style={[appStyles.fullSize, styles.formContainer]}>
          {getForm()}
        </KeyboardAvoidingView>
      </View>
    </ScreenWrapper>
  )
}

const styles = StyleSheet.create({
  formContainer: {
    ...appStyles.fullSize,
    paddingHorizontal: 20,
    height: '100%',
  },
})
