import React from 'react'
import { View, StyleSheet, Platform } from 'react-native'

import { Button, Separator } from '../../../components'
import useSafeAreaPaddedStyle from '../../../hooks/useSafeAreaPaddedStyle'

interface FormPaginatorProps {
  hasBack?: boolean
  nextDisabled?: boolean
  nextText?: string
  backText?: string
  onNext: () => void
  onBack?: () => void
}

export const FormPaginator: React.FC<FormPaginatorProps> = ({
  hasBack = true,
  nextDisabled = false,
  nextText = 'Turpināt',
  backText = 'Atpakaļ',
  onNext,
  onBack,
}) => (
  <View style={useSafeAreaPaddedStyle({ insetBottom: true }, styles.paginator)}>
    <Button disabled={nextDisabled} text={nextText} onPress={onNext} />
    <Separator height={15} />
    <View style={styles.backButtonContainer}>
      {hasBack && <Button outlined={true} text={backText} onPress={onBack} />}
    </View>
  </View>
)

const styles = StyleSheet.create({
  paginator: {
    zIndex: -1,
    marginTop: 'auto',
    marginBottom: Platform.OS === 'web' ? 50 : 0,
  },
  backButtonContainer: {
    height: 60,
  },
})
