import React from 'react'
import { Image, SafeAreaView, StyleSheet, Text } from 'react-native'

import { useNavigation } from '@react-navigation/native'

import { Button, ScreenWrapper, Separator } from '../../components'
import { MainStackNavigationType } from '../../types/navigation-types'

export default (): JSX.Element => {
  const { replace } =
    useNavigation<MainStackNavigationType<'UserFormSuccess'>>()

  return (
    <ScreenWrapper>
      <SafeAreaView style={styles.container}>
        <Image style={styles.logo} source={require('../../assets/logo.png')} />
        <Separator height={50} />
        <Text style={styles.titleText}>Profila informācija saglabāta</Text>
        <Separator height={50} />
        <Text style={styles.subtitleText}>Satiekam citus Tērbatiešus</Text>
        <Separator height={50} />
        <Button text="Turpināt" onPress={() => replace('Home')} />
      </SafeAreaView>
    </ScreenWrapper>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    width: 250,
    height: 250,
  },
  titleText: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  subtitleText: {
    fontSize: 16,
    fontWeight: 'normal',
  },
})
