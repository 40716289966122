import React from 'react'
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native'

import { SvgIcon } from './SvgIcon'
import Colors from '../styles/Colors'

interface ProfileImageProps {
  dimensions: number
  uri?: string | null
  onEditPress?: () => void
}

export const ProfileImage: React.FC<ProfileImageProps> = ({
  uri,
  dimensions,
  onEditPress,
}) => {
  const style = {
    width: dimensions,
    height: dimensions,
    borderRadius: dimensions / 2,
  }

  return (
    <TouchableOpacity
      style={styles.pictureContainer}
      disabled={!onEditPress}
      onPress={onEditPress}>
      {uri ? (
        <Image style={style} source={{ uri }} />
      ) : (
        <SvgIcon
          style={style}
          name="profile"
          color={Colors.black}
          height={dimensions}
          width={dimensions}
        />
      )}
      {onEditPress && (
        <View style={styles.plusIcon}>
          <SvgIcon name="plus" color="black" height={30} width={30} />
        </View>
      )}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  plusIcon: {
    right: -5,
    bottom: -5,
    padding: 4,
    borderRadius: 19,
    position: 'absolute',
    backgroundColor: Colors.white,
  },
  pictureContainer: {
    alignSelf: 'center',
  },
})
