import React, { useEffect, useState } from 'react'
import { SafeAreaView, StyleSheet, Text, View } from 'react-native'

import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import DropDownPicker from 'react-native-dropdown-picker'

import { Header, Separator, Button, ScreenWrapper } from '../../components'
import PickerEmptyComponent from '../../components/PickerEmptyComponent'
import { useFirebase } from '../../providers/FirebaseProvider'
import appStyles from '../../styles/app-styles'
import {
  MainStackParamsType,
  MainStackNavigationType,
} from '../../types/navigation-types'

export default (): JSX.Element => {
  const { params } = useRoute<RouteProp<MainStackParamsType, 'Filter'>>()
  const { navigate, goBack } =
    useNavigation<MainStackNavigationType<'Filter'>>()
  const { studyFields, studyLevels, selectedCities, selectedUniversities } =
    useFirebase()

  const [universitiesOpen, setUniversitiesOpen] = useState(false)
  const [pickedUniversities, setPickedUniversities] = useState<string[]>([])
  const [countriesOpen, setCountriesOpen] = useState(false)
  const [pickedCountries, setPickedCountries] = useState<string[]>([])
  const [citiesOpen, setCitiesOpen] = useState(false)
  const [pickedCities, setPickedCities] = useState<string[]>([])
  const [studyLevelsOpen, setStudyLevelsOpen] = useState(false)
  const [pickedStudyLevels, setPickedStudyLevels] = useState<string[]>([])
  const [studyFieldsOpen, setStudyFieldsOpen] = useState(false)
  const [pickedStudyFields, setPickedStudyFields] = useState<string[]>([])

  useEffect(() => {
    setPickedUniversities(params?.filterUniversities ?? [])
    setPickedStudyLevels(params?.filterLevels ?? [])
    setPickedStudyFields(params?.filterFields ?? [])
    setPickedCountries(params?.filterCountries ?? [])
    setPickedCities(params?.filterCities ?? [])
  }, [params])

  return (
    <ScreenWrapper>
      <SafeAreaView style={appStyles.fullSize}>
        <Header onGoBack={() => goBack()} />
        <Separator height={20} />
        <Text style={styles.filterText}>Atlasi pēc parametriem</Text>
        <Separator height={20} />
        <View style={styles.container}>
          <Text>Valsts</Text>
          <Separator height={5} />
          <DropDownPicker
            multiple
            mode="BADGE"
            zIndex={5}
            zIndexInverse={6}
            showBadgeDot={false}
            open={countriesOpen}
            value={pickedCountries}
            setOpen={setCountriesOpen}
            placeholder="Valsts"
            setValue={setPickedCountries}
            items={[...new Set(selectedCities.map(city => city.country))].map(
              country => ({
                label: country,
                value: country,
              }),
            )}
            onOpen={() => {
              setStudyFieldsOpen(false)
              setStudyLevelsOpen(false)
              setCitiesOpen(false)
              setUniversitiesOpen(false)
            }}
          />
          <Separator height={30} />
          <Text>Pilsēta</Text>
          <Separator height={5} />
          <DropDownPicker
            multiple
            mode="BADGE"
            zIndex={4}
            zIndexInverse={7}
            showBadgeDot={false}
            open={citiesOpen}
            // @ts-ignore
            value={pickedCities}
            setOpen={setCitiesOpen}
            placeholder="Pilsēta"
            setValue={setPickedCities}
            items={selectedCities.map(city => ({
              label: city.city,
              value: city.city,
            }))}
            onOpen={() => {
              setStudyFieldsOpen(false)
              setStudyLevelsOpen(false)
              setCountriesOpen(false)
              setUniversitiesOpen(false)
            }}
          />
          <Separator height={30} />
          <Text>Izglītības iestāde</Text>
          <Separator height={5} />
          <DropDownPicker
            multiple
            mode="BADGE"
            zIndex={3}
            zIndexInverse={8}
            showBadgeDot={false}
            open={universitiesOpen}
            // @ts-ignore
            value={pickedUniversities}
            setOpen={setUniversitiesOpen}
            placeholder="Izglītības iestāde"
            setValue={setPickedUniversities}
            items={selectedUniversities.map(uni => ({
              label: uni.University,
              value: uni.University,
            }))}
            onOpen={() => {
              setStudyFieldsOpen(false)
              setStudyLevelsOpen(false)
              setCountriesOpen(false)
              setCitiesOpen(false)
            }}
          />
          <Separator height={30} />
          <Text>Studiju līmenis</Text>
          <Separator height={5} />
          <DropDownPicker
            multiple
            mode="BADGE"
            zIndex={2}
            zIndexInverse={9}
            showBadgeDot={false}
            open={studyLevelsOpen}
            value={pickedStudyLevels}
            setOpen={setStudyLevelsOpen}
            placeholder="Studiju līmenis"
            setValue={setPickedStudyLevels}
            items={studyLevels.map(level => ({
              label: level.data.name,
              value: level.id,
            }))}
            onOpen={() => {
              setStudyFieldsOpen(false)
              setCitiesOpen(false)
              setCountriesOpen(false)
              setUniversitiesOpen(false)
            }}
          />
          <Separator height={30} />
          <Text>Studiju novirziens</Text>
          <Separator height={5} />
          <DropDownPicker
            multiple
            searchable
            mode="BADGE"
            zIndex={1}
            zIndexInverse={10}
            showBadgeDot={false}
            open={studyFieldsOpen}
            // @ts-ignore
            value={pickedStudyFields}
            setOpen={setStudyFieldsOpen}
            searchPlaceholder="Meklēt..."
            setValue={setPickedStudyFields}
            placeholder="Studiju novirziens"
            ListEmptyComponent={PickerEmptyComponent}
            items={studyFields.map(field => ({
              label: field.data.name,
              value: field.id,
            }))}
            onOpen={() => {
              setStudyLevelsOpen(false)
              setCitiesOpen(false)
              setCountriesOpen(false)
              setUniversitiesOpen(false)
            }}
          />
          <Separator height={50} />
          <Button
            outlined
            text="Notīrīt filtrus"
            onPress={() => {
              // @ts-ignore
              navigate({
                name: 'Home',
                merge: true,
                params: {
                  filterLevels: [],
                  filterFields: [],
                  filterCountries: [],
                  filterCities: [],
                  filterUniversities: [],
                  filtersReset: true,
                },
              })
            }}
          />
          <Separator height={10} />
          <Button
            text="Atlasīt"
            onPress={() => {
              // @ts-ignore
              navigate({
                name: 'Home',
                merge: true,
                params: {
                  filterLevels: pickedStudyLevels,
                  filterFields: pickedStudyFields,
                  filterCountries: pickedCountries,
                  filterUniversities: pickedUniversities,
                  filterCities: pickedCities,
                },
              })
            }}
          />

          <Separator height={10} />
        </View>
      </SafeAreaView>
    </ScreenWrapper>
  )
}

const styles = StyleSheet.create({
  container: {
    ...appStyles.form,
    ...appStyles.fullSize,
    paddingHorizontal: 20,
  },
  filterText: {
    fontSize: 18,
    fontWeight: 'bold',
    alignSelf: 'center',
  },
})
