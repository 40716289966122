import React, { useState } from 'react'
import {
  Text,
  TextStyle,
  StyleSheet,
  TouchableOpacityProps,
  Pressable,
} from 'react-native'

import Colors from '../styles/Colors'

export const Button: React.FC<
  Omit<TouchableOpacityProps, 'children'> & {
    text: string
    outlined?: boolean
    textStyle?: TextStyle
  }
> = ({ text, outlined, textStyle, disabled, ...props }) => {
  const [hover, setHover] = useState(false)

  return (
    <Pressable
      onHoverIn={() => setHover(true)}
      onHoverOut={() => setHover(false)}
      {...props}
      disabled={disabled}
      style={({ pressed }) => [
        styles.buttonWrapper,
        outlined ? styles.outlineBackground : styles.filledBackground,
        hover && styles.hover,
        pressed && styles.pressed,
        disabled && styles.disabled,
      ]}>
      <Text
        style={[
          styles.text,
          outlined && !hover ? styles.actionText : styles.contrastText,
          textStyle,
        ]}>
        {text}
      </Text>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  buttonWrapper: {
    width: '100%',
    alignSelf: 'center',
    overflow: 'hidden',
    maxWidth: 244,
    borderRadius: 27,
  },
  filledBackground: {
    backgroundColor: Colors.action,
    color: Colors.actionText,
  },
  outlineBackground: {
    borderWidth: 1,
    borderColor: Colors.action,
    backgroundColor: 'transparent',
    color: Colors.action,
  },
  text: {
    padding: 15,
    fontWeight: '500',
    textAlign: 'center',
  },
  contrastText: {
    color: Colors.actionText,
  },
  actionText: {
    color: Colors.action,
  },
  hover: {
    backgroundColor: Colors.actionHover,
    color: Colors.actionText,
  },
  pressed: {
    backgroundColor: Colors.actionPressed,
    color: Colors.actionText,
  },
  disabled: {
    backgroundColor: Colors.actionDisabled,
    color: Colors.actionText,
  },
})
