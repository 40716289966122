import React from 'react'
import { StyleSheet, View } from 'react-native'

import Colors from '../styles/Colors'

interface Props {
  width?: number
  height?: number
  isLine?: boolean
}

/**
 * Separator component to render blank vertical/horizontal space.
 * @param height - `number` - the height of the separator
 * @param width - `number` - the width of the separator
 * @param isLine - `boolean` - if true makes the separator visible as a horizontal line
 */
export const Separator: React.FC<Props> = ({
  width = 1,
  height = 1,
  isLine = false,
}) => <View style={[{ height, width }, isLine && styles.line]} />

const styles = StyleSheet.create({
  line: {
    height: 1,
    width: '100%',
    backgroundColor: Colors.gray,
    alignSelf: 'center',
  },
})
