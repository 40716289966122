import React from 'react'
import { StatusBar } from 'react-native'

import { ActionSheetProvider } from '@expo/react-native-action-sheet'
import { SafeAreaProvider } from 'react-native-safe-area-context'

import { Navigation } from './navigation'
import { FirebaseProvider, TerbataProvider } from './providers'

const App = (): JSX.Element => (
  <FirebaseProvider>
    <TerbataProvider>
      <ActionSheetProvider>
        <SafeAreaProvider>
          <StatusBar
            translucent
            barStyle="dark-content"
            backgroundColor="#00000000"
          />
          <Navigation />
        </SafeAreaProvider>
      </ActionSheetProvider>
    </TerbataProvider>
  </FirebaseProvider>
)

export default App
