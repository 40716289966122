import React from 'react'
import { StyleSheet, Text, TextStyle, ViewStyle, View } from 'react-native'

import Colors from '../styles/Colors'

interface ErrorMessageProps {
  error: string
  containerStyle?: ViewStyle
  textStyle?: TextStyle
}

export const ErrorMessage: React.FunctionComponent<ErrorMessageProps> = ({
  error,
  containerStyle,
  textStyle,
}) => (
  <View style={containerStyle}>
    {error ? (
      <Text style={[styles.errorMessage, textStyle]}>{error}</Text>
    ) : (
      <Text style={[styles.errorMessage, styles.hidden, textStyle]}>
        Placeholder text
      </Text>
    )}
  </View>
)

const styles = StyleSheet.create({
  errorMessage: {
    color: Colors.errorText,
  },
  hidden: {
    opacity: 0,
  },
})
