import { useCallback, useState } from 'react'
import { Platform } from 'react-native'

import { useActionSheet } from '@expo/react-native-action-sheet'
import {
  Asset,
  launchCamera,
  launchImageLibrary,
} from 'react-native-image-picker'

export const usePicturePicker = (
  initialPicture?: string,
): [Asset | string | undefined, () => void] => {
  const [picture, setPicture] = useState<Asset | string | undefined>(
    initialPicture,
  )
  const { showActionSheetWithOptions } = useActionSheet()

  const openCameraPhoto = useCallback(() => {
    launchCamera({ mediaType: 'photo' }, response => {
      if (response.assets) setPicture(response.assets[0])
    })
  }, [setPicture])

  const openMediaLibrary = useCallback(() => {
    launchImageLibrary(
      {
        mediaType: 'photo',
        selectionLimit: 1,
      },
      response => {
        if (response.assets) setPicture(response.assets[0])
      },
    )
  }, [setPicture])

  const showAddPictureAlert = () => {
    showActionSheetWithOptions(
      {
        options: ['Uzņemt bildi', 'Pievienot no galerijas', 'Atcelt'],
        cancelButtonIndex: 2,
        disabledButtonIndices: Platform.OS === 'web' ? [0] : [],
        containerStyle: { position: 'relative', bottom: 0, left: 0, right: 0 },
      },
      buttonIndex => {
        switch (buttonIndex) {
          case 0:
            openCameraPhoto()
            break
          case 1:
            openMediaLibrary()
            break
          default:
            break
        }
      },
    )
  }

  const showRemovePictureAlert = () => {
    showActionSheetWithOptions(
      {
        options: [
          'Uzņemt bildi',
          'Pievienot no galerijas',
          'Dzēst bildi',
          'Atcelt',
        ],
        cancelButtonIndex: 3,
        destructiveButtonIndex: 2,
        disabledButtonIndices: Platform.OS === 'web' ? [0] : [],
      },
      buttonIndex => {
        switch (buttonIndex) {
          case 0:
            openCameraPhoto()
            break
          case 1:
            openMediaLibrary()
            break
          case 2:
            setPicture(undefined)
            break
          default:
            break
        }
      },
    )
  }

  const showPictureOptions = () => {
    if (picture) showRemovePictureAlert()
    else showAddPictureAlert()
  }

  return [picture, showPictureOptions]
}
