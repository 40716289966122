import React from 'react'

import { createStackNavigator } from '@react-navigation/stack'

import VerifyEmailScreen from '../screens/verify-email/VerifyEmailScreen'
import type { UnverifiedStackParamsType } from '../types/navigation-types'

export default (): JSX.Element => {
  const UnverifiedStack = createStackNavigator<UnverifiedStackParamsType>()

  return (
    <UnverifiedStack.Navigator
      initialRouteName="VerifyEmail"
      screenOptions={{ headerShown: false }}>
      <UnverifiedStack.Screen
        name="VerifyEmail"
        component={VerifyEmailScreen}
        options={{ title: 'Apstiprināt e-pastu' }}
      />
    </UnverifiedStack.Navigator>
  )
}
