import React, { useEffect, useState } from 'react'
import { Platform, StyleSheet, Text, TextInput, View } from 'react-native'

import DropDownPicker from 'react-native-dropdown-picker'

import { Button, ErrorMessage, Header, Separator } from '../../../components'
import { useFirebase, useTerbata } from '../../../providers'
import appStyles from '../../../styles/app-styles'
import Colors from '../../../styles/Colors'
import { ProfileEditForm, UniversityData, UserUniversity } from '../../../types'
import { getYearList } from '../../../utils'

const PickerEmptyComponent = () => (
  <Text style={styles.pickerEmpty}>Tādu neatradām</Text>
)

interface ProfileUniversityFormProps {
  onAdd: (city: UserUniversity) => void
}

type InputType =
  | 'country'
  | 'university'
  | 'yearSince'
  | 'yearTo'
  | 'level'
  | 'field'
  | 'program'

const dropdownMode = Platform.OS === 'web' ? undefined : 'MODAL'

export const ProfileUniversityForm: React.FC<ProfileUniversityFormProps> = ({
  onAdd,
}) => {
  const [countries, setCountries] = useState<string[]>([])
  const [apiUniversities, setApiUniversities] = useState<UniversityData[]>()
  const { studyLevels, studyFields, getCountries, getCountryUniversities } =
    useFirebase()
  const { setProfileEditFormState } = useTerbata()

  const [country, setCountry] = useState<string>()
  const [universityName, setUniversityName] = useState<string>()
  const [studyLevel, setStudyLevel] = useState<string>()
  const [studyField, setStudyField] = useState<string>()
  const [program, setProgram] = useState<string>()

  const [countriesOpen, setCountriesOpen] = useState(false)
  const [universitiesOpen, setUniversitiesOpen] = useState(false)

  const [yearListSince] = useState(getYearList())
  const [yearListTo, setYearListTo] = useState(getYearList())
  const [yearSince, setYearSince] = useState<number>()
  const [yearTo, setYearTo] = useState<number>()

  useEffect(() => {
    if (yearSince) {
      const list = getYearList(yearSince)
      setYearListTo(list)
      if (list.map(item => item.value).includes(yearSince)) {
        setYearTo(undefined)
      }
    }
  }, [yearSince])

  const [yearSinceOpen, setYearSinceOpen] = useState(false)
  const [yearToOpen, setYearToOpen] = useState(false)
  const [studyLevelsOpen, setStudyLevelsOpen] = useState(false)
  const [studyFieldsOpen, setStudyFieldsOpen] = useState(false)

  const [errors, setErrors] = useState<InputType[]>([])

  useEffect(() => {
    getCountries().then(setCountries)
  }, [getCountries])

  useEffect(() => {
    if (country) getCountryUniversities({ country }).then(setApiUniversities)
  }, [country, getCountryUniversities])

  const onSubmit = () => {
    const unfilledInputs: InputType[] = []
    if (!country) unfilledInputs.push('country')
    if (country && !universityName) unfilledInputs.push('university')
    if (!yearSince) unfilledInputs.push('yearSince')
    if (!yearTo) unfilledInputs.push('yearTo')
    if (!studyLevel) unfilledInputs.push('level')
    if (!studyField) unfilledInputs.push('field')
    if (!program) unfilledInputs.push('program')
    setErrors(unfilledInputs)
    if (unfilledInputs.length > 0) return
    onAdd({
      country,
      university: universityName,
      startDate: yearSince,
      endDate: yearTo,
      level: studyLevel,
      field: studyField,
      program,
    })
    setProfileEditFormState(ProfileEditForm.Main)
  }

  const onGoBack = () => {
    setProfileEditFormState(ProfileEditForm.Main)
  }

  return (
    <View>
      <Header onGoBack={onGoBack} />
      <Separator height={20} />
      <View style={appStyles.form}>
        <Text style={appStyles.textInputLabel}>
          Ievadi informāciju par augstskolu, kurā esi studējis, studē vai plāno
          studēt:
        </Text>
        <Separator height={20} />
        <Text style={appStyles.textInputLabel}>
          Kurā valstī atrodas iestāde?
        </Text>
        <DropDownPicker
          searchable
          zIndex={6}
          // @ts-ignore
          items={countries.map(c => ({
            label: c,
            value: c,
          }))}
          zIndexInverse={3}
          style={[
            styles.input,
            errors.includes('country') && styles.inputError,
          ]}
          open={countriesOpen}
          // @ts-ignore
          value={country}
          listMode={dropdownMode}
          setValue={setCountry}
          setOpen={setCountriesOpen}
          placeholder="Izvēlies valsti"
          searchPlaceholder="Meklēt..."
          onOpen={() => {
            setYearToOpen(false)
            setYearSinceOpen(false)
            setUniversitiesOpen(false)
            setStudyFieldsOpen(false)
            setStudyLevelsOpen(false)
          }}
          onPress={() => setErrors(c => c.filter(i => i !== 'country'))}
        />
        {apiUniversities && (
          <>
            <Separator height={20} />
            <Text style={appStyles.textInputLabel}>Iestādes nosaukums</Text>
            <DropDownPicker
              searchable
              zIndex={5}
              items={apiUniversities.map(u => ({
                label: u.University,
                value: u.University,
              }))}
              zIndexInverse={4}
              style={[
                styles.input,
                errors.includes('university') && styles.inputError,
              ]}
              open={universitiesOpen}
              // @ts-ignore
              value={universityName}
              listMode={dropdownMode}
              setValue={setUniversityName}
              setOpen={setUniversitiesOpen}
              placeholder="Izvēlies iestādi"
              searchPlaceholder="Meklēt..."
              onOpen={() => {
                setYearToOpen(false)
                setYearSinceOpen(false)
                setCountriesOpen(false)
                setStudyFieldsOpen(false)
                setStudyLevelsOpen(false)
              }}
              onPress={() => setErrors(c => c.filter(i => i !== 'university'))}
            />
          </>
        )}
        <Separator height={20} />
        <Text style={appStyles.textInputLabel}>
          Kurā gadā šajā iestādē uzsāki vai plāno uzsākt mācības?
        </Text>
        <DropDownPicker
          zIndex={4}
          zIndexInverse={5}
          // @ts-ignore
          items={yearListSince}
          style={[
            styles.input,
            errors.includes('yearSince') && styles.inputError,
          ]}
          open={yearSinceOpen}
          // @ts-ignore
          value={yearSince}
          listMode={dropdownMode}
          setOpen={setYearSinceOpen}
          placeholder="Izvēlies gadu"
          // @ts-ignore
          setValue={setYearSince}
          onOpen={() => {
            setYearToOpen(false)
            setStudyLevelsOpen(false)
            setStudyFieldsOpen(false)
            setCountriesOpen(false)
            setUniversitiesOpen(false)
          }}
          onPress={() => setErrors(c => c.filter(i => i !== 'yearSince'))}
        />
        <Separator height={20} />
        <Text style={appStyles.textInputLabel}>
          Kurā gadā pabeidzi vai plāno pabeigt mācības?
        </Text>
        <DropDownPicker
          zIndex={3}
          zIndexInverse={6}
          // @ts-ignore
          items={yearListTo}
          open={yearToOpen}
          // @ts-ignore
          value={yearTo}
          style={[styles.input, errors.includes('yearTo') && styles.inputError]}
          listMode={dropdownMode}
          setOpen={setYearToOpen}
          placeholder="Izvēlies gadu"
          // @ts-ignore
          setValue={setYearTo}
          onOpen={() => {
            setYearSinceOpen(false)
            setStudyLevelsOpen(false)
            setStudyFieldsOpen(false)
            setCountriesOpen(false)
            setUniversitiesOpen(false)
          }}
          onPress={() => setErrors(c => c.filter(i => i !== 'yearTo'))}
        />
        <Separator height={20} />
        <Text style={appStyles.textInputLabel}>Studiju līmenis</Text>
        <DropDownPicker
          zIndex={2}
          zIndexInverse={7}
          // @ts-ignore
          value={studyLevel}
          style={[styles.input, errors.includes('level') && styles.inputError]}
          open={studyLevelsOpen}
          listMode={dropdownMode}
          // @ts-ignore
          setValue={setStudyLevel}
          setOpen={setStudyLevelsOpen}
          placeholder="Studiju līmenis"
          items={studyLevels.map(level => ({
            label: level.data.name,
            value: level.id,
          }))}
          onOpen={() => {
            setYearSinceOpen(false)
            setYearToOpen(false)
            setStudyFieldsOpen(false)
            setCountriesOpen(false)
            setUniversitiesOpen(false)
          }}
          onPress={() => setErrors(c => c.filter(i => i !== 'level'))}
        />
        <Separator height={20} />
        <Text style={appStyles.textInputLabel}>Studiju novirziens</Text>
        <DropDownPicker
          searchable
          zIndex={1}
          zIndexInverse={8}
          // @ts-ignore
          value={studyField}
          style={[styles.input, errors.includes('field') && styles.inputError]}
          open={studyFieldsOpen}
          listMode={dropdownMode}
          // @ts-ignore
          setValue={setStudyField}
          setOpen={setStudyFieldsOpen}
          searchPlaceholder="Meklēt..."
          placeholder="Studiju novirziens"
          ListEmptyComponent={PickerEmptyComponent}
          items={studyFields.map(field => ({
            label: field.data.name,
            value: field.id,
          }))}
          onOpen={() => {
            setYearSinceOpen(false)
            setYearToOpen(false)
            setStudyLevelsOpen(false)
            setCountriesOpen(false)
            setUniversitiesOpen(false)
          }}
          onPress={() => setErrors(c => c.filter(i => i !== 'field'))}
        />
        <Separator height={20} />
        <Text style={appStyles.textInputLabel}>
          Studiju programma (lūdzu, ievadi angliski)
        </Text>
        <TextInput
          value={program}
          placeholder="Ievadi studiju programmu"
          autoCapitalize="words"
          placeholderTextColor={Colors.black}
          style={[
            styles.input,
            errors.includes('program') && styles.inputError,
          ]}
          onChangeText={p => {
            setProgram(p)
            setErrors(c => c.filter(i => i !== 'program'))
          }}
        />
        <Separator height={30} />
        {errors.length > 0 && (
          <ErrorMessage
            error={'Lūdzu, aizpildi visus nepieciešamos ievadlaukus!'}
            containerStyle={styles.errorContainer}
          />
        )}
      </View>
      <Separator height={30} />
      <View style={styles.submitContainer}>
        <Button
          text="Pievienot universitāti"
          style={styles.submitButton}
          onPress={onSubmit}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  input: {
    height: 50,
    paddingHorizontal: 15,
    backgroundColor: Colors.gray,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: Colors.gray,
  },
  inputError: {
    borderColor: Colors.errorText,
  },
  errorContainer: {
    margin: 'auto',
  },
  submitContainer: {
    zIndex: -5,
  },
  submitButton: {
    marginTop: 'auto',
    marginBottom: Platform.OS === 'web' ? 50 : 0,
  },
  pickerEmpty: {
    alignSelf: 'center',
    paddingVertical: 12,
  },
})
