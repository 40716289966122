import React, {
  useMemo,
  useContext,
  createContext,
  PropsWithChildren,
} from 'react'

import type { User } from 'firebase/auth'
import { Asset } from 'react-native-image-picker'

import { useFirebaseClient } from '../api/firebase-client'
import { CityData } from '../types/city-types'
import { StudyDataEntry } from '../types/study-data-types'
import { UniversityData } from '../types/university-types'
import { UserData } from '../types/user-types'

export type FirebaseContextType = {
  isLoading: boolean
  user: User | null
  userData: any | null
  studyLevels: StudyDataEntry[]
  studyFields: StudyDataEntry[]
  selectedCities: CityData[]
  selectedUniversities: UniversityData[]
  register: (params: {
    email: string
    password: string
    name: string
    surname: string
  }) => Promise<void>
  sendVerificationEmail: () => Promise<void>
  login: (params: { email: string; password: string }) => Promise<void>
  logout: () => Promise<void>
  uploadPicture: (params: { picture: Asset }) => Promise<string>
  refreshCurrentUser: () => Promise<void>
  getUserData: () => Promise<void>
  deletePicture: () => Promise<void>
  getUserById: (params: { id: string }) => Promise<UserData>
  saveUserData: (params: { newUserData: UserData }) => Promise<void>
  getUsersPage: (
    lastUser?: any,
  ) => Promise<{ users: UserData[]; lastUser?: any }>
  getAllUsers: () => Promise<UserData[]>
  deleteUser: () => Promise<void>
  saveSelectedCities: (params: { newCities: CityData[] }) => Promise<void>
  saveSelectedUniversities: (params: {
    newUniversities: UniversityData[]
  }) => Promise<void>
  sendPasswordResetEmail: (email: string) => Promise<void>
  getCountries: () => Promise<string[]>
  getCountryCities: (params: { country: string }) => Promise<CityData[]>
  getCountryUniversities: (params: {
    country: string
  }) => Promise<UniversityData[]>
}

const FirebaseContext = createContext<FirebaseContextType>({
  isLoading: false,
  user: null,
  userData: null,
  studyLevels: [],
  studyFields: [],
  selectedCities: [],
  selectedUniversities: [],
  register: () => Promise.reject(),
  sendVerificationEmail: () => Promise.reject(),
  login: () => Promise.reject(),
  logout: () => Promise.reject(),
  refreshCurrentUser: () => Promise.reject(),
  uploadPicture: () => Promise.reject(),
  getUserData: () => Promise.reject(),
  deletePicture: () => Promise.reject(),
  getUserById: () => Promise.reject(),
  saveUserData: () => Promise.reject(),
  getUsersPage: () => Promise.reject(),
  getAllUsers: () => Promise.reject(),
  deleteUser: () => Promise.reject(),
  saveSelectedCities: () => Promise.reject(),
  saveSelectedUniversities: () => Promise.reject(),
  sendPasswordResetEmail: () => Promise.reject(),
  getCountries: () => Promise.reject(),
  getCountryCities: () => Promise.reject(),
  getCountryUniversities: () => Promise.reject(),
})

export const FirebaseProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const {
    isLoading,
    user,
    userData,
    studyLevels,
    studyFields,
    selectedCities,
    selectedUniversities,
    register,
    sendVerificationEmail,
    login,
    logout,
    refreshCurrentUser,
    uploadPicture,
    getUserData,
    deletePicture,
    getUserById,
    saveUserData,
    getUsersPage,
    getAllUsers,
    deleteUser,
    saveSelectedCities,
    saveSelectedUniversities,
    sendPasswordResetEmail,
    getCountries,
    getCountryCities,
    getCountryUniversities,
  } = useFirebaseClient()

  return (
    <FirebaseContext.Provider
      value={useMemo(
        () => ({
          isLoading,
          user,
          userData,
          studyLevels,
          studyFields,
          selectedCities,
          selectedUniversities,
          register,
          sendVerificationEmail,
          login,
          logout,
          refreshCurrentUser,
          uploadPicture,
          getUserData,
          deletePicture,
          getUserById,
          saveUserData,
          getUsersPage,
          getAllUsers,
          deleteUser,
          saveSelectedCities,
          saveSelectedUniversities,
          sendPasswordResetEmail,
          getCountries,
          getCountryCities,
          getCountryUniversities,
        }),
        [
          isLoading,
          user,
          userData,
          studyLevels,
          studyFields,
          selectedCities,
          selectedUniversities,
          register,
          sendVerificationEmail,
          login,
          logout,
          refreshCurrentUser,
          uploadPicture,
          getUserData,
          deletePicture,
          getUserById,
          saveUserData,
          getUsersPage,
          getAllUsers,
          deleteUser,
          saveSelectedCities,
          saveSelectedUniversities,
          sendPasswordResetEmail,
          getCountries,
          getCountryCities,
          getCountryUniversities,
        ],
      )}>
      {children}
    </FirebaseContext.Provider>
  )
}

export function useFirebase() {
  return useContext(FirebaseContext)
}
