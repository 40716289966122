import React from 'react'
import { StyleSheet, Text } from 'react-native'

export default () => <Text style={styles.text}>Tādu neatradām</Text>

const styles = StyleSheet.create({
  text: {
    alignSelf: 'center',
    paddingVertical: 12,
  },
})
