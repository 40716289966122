import React, { useEffect, useState } from 'react'
import { StyleSheet, View, Text, TextInput, Platform } from 'react-native'

import DropDownPicker from 'react-native-dropdown-picker'

import { FormPaginator } from './FormPaginator'
import { ErrorMessage, Separator } from '../../../components'
import { useFirebase, useTerbata } from '../../../providers'
import appStyles from '../../../styles/app-styles'
import Colors from '../../../styles/Colors'
import { UserUniversity, UserForm } from '../../../types'
import { getYearList } from '../../../utils'

type InputType = 'field' | 'program' | 'startDate' | 'endDate'

const PickerEmptyComponent = () => (
  <Text style={styles.pickerEmpty}>Tādu neatradām</Text>
)

interface UniversityForm2Props {
  university: UserUniversity
  onAddUniversity: (university: UserUniversity) => void
}

export const UniversityForm2: React.FC<UniversityForm2Props> = ({
  university,
  onAddUniversity,
}) => {
  const { studyFields } = useFirebase()
  const { setUserFormState } = useTerbata()

  const dropdownMode = Platform.OS === 'web' ? undefined : 'MODAL'

  const [errors, setErrors] = useState<InputType[]>([])

  const [studyFieldsOpen, setStudyFieldsOpen] = useState(false)

  const [universityInfo, setUniversityInfo] = useState(university)

  const [yearListSince] = useState(getYearList())
  const [yearListTo, setYearListTo] = useState(getYearList())

  const [startDateOpen, setStartDateOpen] = useState(false)
  const [endDateOpen, setEndDateOpen] = useState(false)

  useEffect(() => {
    const startYear = universityInfo.startDate
    if (startYear) {
      const list = getYearList(startYear)
      setYearListTo(list)
      if (list.map(item => item.value).includes(startYear)) {
        setUniversityInfo(u => ({ ...u, endDate: undefined }))
      }
    }
  }, [universityInfo.startDate])

  const onChangeStudyField = (callBack: () => string | null) => {
    const response = callBack()
    const field = response === null ? undefined : response
    setErrors(c => c.filter(i => i !== 'field'))
    setUniversityInfo({ ...universityInfo, field })
  }

  const onChangeStudyProgram = (program: string) => {
    setErrors(c => c.filter(i => i !== 'program'))
    setUniversityInfo({ ...universityInfo, program })
  }

  const onChangeStartDate = (callBack: () => number | null) => {
    const response = callBack()
    const year = response === null ? undefined : response
    setErrors(c => c.filter(i => i !== 'startDate'))
    setUniversityInfo({ ...universityInfo, startDate: year })
  }

  const onChangeEndDate = (callBack: () => number | null) => {
    const response = callBack()
    const year = response === null ? undefined : response
    setErrors(c => c.filter(i => i !== 'endDate'))
    setUniversityInfo({ ...universityInfo, endDate: year })
  }

  const onSubmit = () => {
    const { field, program, startDate, endDate } = universityInfo

    const unfilledInputs: InputType[] = []
    if (!field) unfilledInputs.push('field')
    if (!program) unfilledInputs.push('program')
    if (!startDate) unfilledInputs.push('startDate')
    if (!endDate) unfilledInputs.push('endDate')

    setErrors(unfilledInputs)
    if (unfilledInputs.length > 0) return

    onAddUniversity(universityInfo)
    setUserFormState(UserForm.UniversityOverview)
  }

  return (
    <>
      <View style={appStyles.form}>
        <Text style={appStyles.textInputLabel}>
          Ievadi informāciju par augstskolu, kurā esi studējis, studē vai plāno
          studēt:
        </Text>
        <Separator height={20} />
        <Text style={appStyles.textInputLabel}>Studiju novirziens</Text>
        <DropDownPicker
          searchable
          zIndex={3}
          zIndexInverse={4}
          // @ts-ignore
          value={universityInfo.field}
          style={[styles.input, errors.includes('field') && styles.inputError]}
          open={studyFieldsOpen}
          listMode={dropdownMode}
          // @ts-ignore
          setValue={onChangeStudyField}
          setOpen={setStudyFieldsOpen}
          searchPlaceholder="Meklēt..."
          placeholder="Studiju novirziens"
          ListEmptyComponent={PickerEmptyComponent}
          items={studyFields.map(field => ({
            label: field.data.name,
            value: field.id,
          }))}
          onOpen={() => {
            setStartDateOpen(false)
            setEndDateOpen(false)
          }}
        />
        <Separator height={20} />
        <Text style={appStyles.textInputLabel}>
          Studiju programma (lūdzu, ievadi angliski)
        </Text>
        <TextInput
          value={universityInfo.program}
          placeholder="Ievadi studiju programmu"
          autoCapitalize="words"
          placeholderTextColor={Colors.black}
          style={[
            styles.input,
            errors.includes('program') && styles.inputError,
          ]}
          onChangeText={onChangeStudyProgram}
        />
        <Separator height={20} />
        <Text style={appStyles.textInputLabel}>
          Kurā gadā šajā iestādē uzsāki vai plāno uzsākt mācības?
        </Text>
        <DropDownPicker
          zIndex={2}
          zIndexInverse={5}
          // @ts-ignore
          items={yearListSince}
          style={[
            styles.input,
            errors.includes('startDate') && styles.inputError,
          ]}
          open={startDateOpen}
          // @ts-ignore
          value={universityInfo.startDate}
          listMode={dropdownMode}
          setOpen={setStartDateOpen}
          placeholder="Izvēlies gadu"
          // @ts-ignore
          setValue={onChangeStartDate}
          onOpen={() => {
            setStudyFieldsOpen(false)
            setEndDateOpen(false)
          }}
        />
        <Separator height={20} />
        <Text style={appStyles.textInputLabel}>
          Kurā gadā pabeidzi vai plāno pabeigt mācības?
        </Text>
        <DropDownPicker
          zIndex={1}
          zIndexInverse={6}
          // @ts-ignore
          items={yearListTo}
          open={endDateOpen}
          // @ts-ignore
          value={universityInfo.endDate}
          style={[
            styles.input,
            errors.includes('endDate') && styles.inputError,
          ]}
          listMode={dropdownMode}
          setOpen={setEndDateOpen}
          placeholder="Izvēlies gadu"
          // @ts-ignore
          setValue={onChangeEndDate}
          onOpen={() => {
            setStudyFieldsOpen(false)
            setStartDateOpen(false)
          }}
        />
        <Separator height={30} />
        {errors.length > 0 && (
          <ErrorMessage
            error={'Lūdzu, aizpildi visus nepieciešamos ievadlaukus!'}
            containerStyle={styles.errorContainer}
          />
        )}
      </View>
      <Separator height={30} />
      <FormPaginator
        onBack={() => setUserFormState(UserForm.UniversityForm1)}
        onNext={onSubmit}
      />
    </>
  )
}

const styles = StyleSheet.create({
  input: {
    height: 50,
    paddingHorizontal: 15,
    backgroundColor: Colors.gray,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: Colors.gray,
  },
  inputError: {
    borderColor: Colors.errorText,
  },
  errorContainer: {
    margin: 'auto',
  },
  pickerEmpty: {
    alignSelf: 'center',
    paddingVertical: 12,
  },
})
