import { useCallback } from 'react'

import { useFirebase } from '../providers'

export const useStudyData = (): {
  getStudyLevel: (studyLevelKey?: string) => string
  getStudyField: (getStudyFieldKey?: string) => string
} => {
  const { studyFields, studyLevels } = useFirebase()

  const getStudyLevel = useCallback(
    (studyLevelKey?: string) => {
      if (studyLevelKey) {
        const studyLevel = studyLevels.find(level => level.id === studyLevelKey)
        return studyLevel ? studyLevel.data.name : studyLevelKey
      } else {
        return ''
      }
    },
    [studyLevels],
  )

  const getStudyField = useCallback(
    (getStudyFieldKey?: string) => {
      if (getStudyFieldKey) {
        const studyField = studyFields.find(
          field => field.id === getStudyFieldKey,
        )
        return studyField ? studyField.data.name : getStudyFieldKey
      } else {
        return ''
      }
    },
    [studyFields],
  )

  return { getStudyLevel, getStudyField }
}
