import React, { useCallback, useState } from 'react'
import {
  View,
  Platform,
  TextInput,
  StyleSheet,
  KeyboardAvoidingView,
  SafeAreaView,
  Text,
} from 'react-native'

import { useNavigation } from '@react-navigation/native'

import {
  Button,
  Header,
  showAlert,
  Separator,
  ScreenWrapper,
} from '../../components'
import { useFirebase } from '../../providers/FirebaseProvider'
import appStyles from '../../styles/app-styles'
import Colors from '../../styles/Colors'
import { UnauthorizedStackNavigationType } from '../../types/navigation-types'

export default () => {
  const { goBack } = useNavigation<UnauthorizedStackNavigationType<'Welcome'>>()
  const [email, setEmail] = useState('')
  const { sendPasswordResetEmail } = useFirebase()

  const resetPassword = useCallback(
    (mail: string) => {
      if (!mail) {
        showAlert('Lūdzu, ievadiet e-pastu')
        return
      }
      sendPasswordResetEmail(mail)
        .then(() => {
          showAlert('Paroles atjaunošanas saite nosūtīta uz Jūsu e-pastu')
          goBack()
        })
        .catch(error => {
          console.log(error)
          showAlert('Neizdevās nosūtīt atjaunošanas saiti')
        })
    },
    [goBack, sendPasswordResetEmail],
  )

  return (
    <ScreenWrapper>
      <SafeAreaView style={appStyles.fullSize}>
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          style={appStyles.fullSize}>
          <Header onGoBack={goBack} />
          <View style={styles.formContainer}>
            <Text style={styles.titleText}>Atjauno paroli</Text>
            <Separator height={10} />
            <Text style={styles.descriptionText}>
              Saite paroles atjaunošanai tiks nosūtīts uz Jūsu e-pasta adresi
            </Text>
            <Separator height={50} />

            <TextInput
              value={email}
              placeholder="E-pasts"
              inputMode="email"
              autoComplete="email"
              autoCapitalize="none"
              placeholderTextColor={Colors.black}
              style={styles.textInput}
              onChangeText={setEmail}
            />
            <Separator height={30} />
            {Platform.OS !== 'web' && <View style={appStyles.fullSize} />}
            <Button
              text="Atjaunot paroli"
              onPress={() => resetPassword(email)}
            />
            <Separator height={10} />
          </View>
        </KeyboardAvoidingView>
      </SafeAreaView>
    </ScreenWrapper>
  )
}

const styles = StyleSheet.create({
  headerContainer: {
    ...appStyles.row,
    justifyContent: 'center',
  },
  titleText: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  descriptionText: {
    textAlign: 'center',
  },
  formContainer: {
    ...appStyles.fullSize,
    paddingHorizontal: 20,
  },
  textInput: {
    height: 40,
    borderRadius: 20,
    paddingHorizontal: 15,
    backgroundColor: Colors.gray,
  },
})
