import React from 'react'
import { View, StyleSheet, Text } from 'react-native'

import { FormPaginator } from './FormPaginator'
import { Button, EditableList, Separator } from '../../../components'
import { useStudyData } from '../../../hooks/useStudyData'
import { useTerbata } from '../../../providers'
import appStyles from '../../../styles/app-styles'
import Colors from '../../../styles/Colors'
import { UserUniversity, UserForm } from '../../../types'

const listItemFactory = (
  getStudyLevel: (studyLevelKey?: string | undefined) => string,
  getStudyField: (getStudyFieldKey?: string | undefined) => string,
) => {
  return (item: UserUniversity) => (
    <View>
      <Text>{`${item.university} (${item.startDate}-${item.endDate})`}</Text>
      <Text>{getStudyField(item.field)}</Text>
      <Text>{`${getStudyLevel(item.level)} - ${item.program}`}</Text>
    </View>
  )
}

interface UniversityOverviewProps {
  universities: UserUniversity[]
  onDeleteUniversity: (index: number) => void
}

export const UniversityOverview: React.FC<UniversityOverviewProps> = ({
  universities,
  onDeleteUniversity,
}) => {
  const { setUserFormState } = useTerbata()
  const { getStudyLevel, getStudyField } = useStudyData()

  const ListItem = listItemFactory(getStudyLevel, getStudyField)

  return (
    <>
      <View style={appStyles.form}>
        <Text style={[appStyles.textInputLabel, styles.label]}>
          Norādi vismaz vienu augstskolu, kurā esi kādreiz mācījies vai mācies
          šobrīd:
        </Text>
        <EditableList
          items={universities
            .sort((a, b) => Number(a.startDate) - Number(b.startDate))
            .map((university, i) => ({ key: i, data: university }))}
          ItemComponent={ListItem}
          onRemove={onDeleteUniversity}
        />
        <Separator height={20} />
        <Button
          text="Pievienot augstskolu"
          outlined={true}
          onPress={() => setUserFormState(UserForm.UniversityForm1)}
        />
      </View>
      <Separator height={30} />
      <FormPaginator
        nextDisabled={!universities.length}
        onBack={() => setUserFormState(UserForm.CityOverview)}
        onNext={() => setUserFormState(UserForm.FinalForm)}
      />
    </>
  )
}

const styles = StyleSheet.create({
  label: {
    margin: 'auto',
  },
  input: {
    height: 50,
    paddingHorizontal: 15,
    backgroundColor: Colors.gray,
    borderRadius: 20,
    borderWidth: 0,
  },
})
