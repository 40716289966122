import React from 'react'
import { Text, View, StyleSheet, TextInput } from 'react-native'

import type { Asset } from 'react-native-image-picker'

import { FormPaginator } from './FormPaginator'
import { CheckBox, ProfileImage, Separator } from '../../../components'
import { useTerbata } from '../../../providers'
import appStyles from '../../../styles/app-styles'
import Colors from '../../../styles/Colors'
import { UserForm } from '../../../types'

interface FinalFormProps {
  picture: Asset | undefined
  description: string
  phoneNumber: string
  showEmail: boolean
  showPictureOptions: () => void
  onChangeDescription: (description: string) => void
  onChangePhoneNumber: (phoneNumber: string) => void
  onChangeShowEmail: (university: boolean) => void
  onSubmitForm: () => void
}

export const FinalForm: React.FC<FinalFormProps> = ({
  picture,
  description,
  phoneNumber,
  showEmail,
  showPictureOptions,
  onChangeDescription,
  onChangePhoneNumber,
  onChangeShowEmail,
  onSubmitForm,
}) => {
  const { setUserFormState } = useTerbata()

  const onSubmit = () => {
    onSubmitForm()
  }

  return (
    <>
      <View style={appStyles.form}>
        <Text style={styles.labelText}>Foršāk ar bildi</Text>
        <Separator height={20} />
        <ProfileImage
          uri={picture?.uri}
          dimensions={100}
          onEditPress={showPictureOptions}
        />
        <Separator height={20} />
        <Text style={appStyles.textInputLabel}>
          Darba pieredze, intereses vai aizraušanās! Kā pavadi brīvo laiku?
        </Text>
        <TextInput
          multiline
          value={description}
          placeholder="Strādāju Siemens elektronikas nodaļā, šuju cimdus, C1 spāņu valodā vai programmēju aktīvi Pythonā. Patīk pastaigas, klausos džezu, spēlēju basketbolu . . ."
          placeholderTextColor={Colors.description}
          style={[styles.input, styles.multilineTextInput]}
          onChangeText={onChangeDescription}
        />
        <Separator height={20} />
        <Text style={appStyles.textInputLabel}>
          Norādi savu telefona numuru (brīvprātīgi, var vienmēr dzēst):
        </Text>
        <TextInput
          value={phoneNumber}
          placeholder="+123 12345678"
          placeholderTextColor={Colors.description}
          style={[styles.input]}
          onChangeText={onChangePhoneNumber}
        />
        <Separator height={20} />
        <Text style={[appStyles.textInputLabel, styles.showEmailLabel]}>
          Ātrāki saziņai, lūdzu, norādi ka Tavs e-pasts būtu redzams arī citiem
          tērbatiešiem:
        </Text>
        <Separator height={20} />
        <View style={styles.showEmailContainer}>
          <CheckBox
            value={showEmail}
            onValueChange={() => onChangeShowEmail(!showEmail)}
          />
          <Text style={[appStyles.textInputLabel, styles.showEmailLabel]}>
            Vēlos, lai mans e-pasts būtu redzams
          </Text>
        </View>
      </View>
      <Separator height={30} />
      <FormPaginator
        nextText="Pabeigt"
        onBack={() => setUserFormState(UserForm.UniversityOverview)}
        onNext={onSubmit}
      />
    </>
  )
}

const styles = StyleSheet.create({
  labelText: {
    ...appStyles.textInputLabel,
    paddingLeft: 0,
    textAlign: 'center',
  },
  input: {
    height: 50,
    paddingHorizontal: 15,
    backgroundColor: Colors.gray,
    borderRadius: 20,
    borderWidth: 0,
  },
  multilineTextInput: {
    minHeight: 120,
    maxHeight: 200,
    paddingVertical: 16,
    paddingHorizontal: 20,
    textAlignVertical: 'top',
  },
  showEmailLabel: {
    textAlign: 'center',
    paddingLeft: 0,
    paddingBottom: 0,
  },
  showEmailContainer: {
    margin: 'auto',
    gap: 10,
    ...appStyles.inlineContainer,
  },
})
