import { StyleSheet } from 'react-native'

import Colors from './Colors'

export default StyleSheet.create({
  fullSize: {
    flex: 1,
  },
  row: {
    flexDirection: 'row',
  },
  inlineContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  verticalCenter: {
    justifyContent: 'center',
  },
  horizontalCenter: {
    alignItems: 'center',
  },
  textInputLabel: {
    paddingLeft: 10,
    paddingBottom: 10,
  },
  lightShadow: {
    elevation: 4,
    shadowColor: Colors.black,
    shadowOpacity: 0.2,
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowRadius: 14,
  },
  form: {
    maxWidth: 700,
    margin: 'auto',
    width: '100%',
  },
})
