const CURRENT_YEAR = new Date().getFullYear()

export const getYearList = (from?: number) => {
  const yearFrom = from && from > CURRENT_YEAR ? from : CURRENT_YEAR
  const allYears = Array.from({ length: 85 }, (_, i) => yearFrom - i + 6)
  const filteredYears = from
    ? allYears.slice(0, allYears.indexOf(from) + 1)
    : allYears
  return filteredYears.map(year => ({ label: year, value: year }))
}
