/* eslint-disable no-alert */
export const showAlert = (title: string): void => {
  // @ts-ignore
  alert(title)
}

export const showDestructiveAlert = (
  title: string,
  description: string,
  onConfirm: () => void,
): void => {
  // @ts-ignore
  if (confirm(title + ' ' + description)) {
    onConfirm()
  }
}
