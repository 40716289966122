import React, { useState } from 'react'
import {
  View,
  Text,
  Platform,
  StyleSheet,
  KeyboardAvoidingView,
} from 'react-native'

import { useNavigation } from '@react-navigation/native'
import { Asset } from 'react-native-image-picker'

import {
  CityOverview,
  CityForm,
  UniversityOverview,
  UniversityForm1,
  UniversityForm2,
  FinalForm,
} from './components'
import { Header, ScreenWrapper, Separator, showAlert } from '../../components'
import { usePicturePicker } from '../../hooks/usePicturePicker'
import useSafeAreaPaddedStyle, {
  headerOptions,
} from '../../hooks/useSafeAreaPaddedStyle'
import { useFirebase, useTerbata } from '../../providers'
import appStyles from '../../styles/app-styles'
import { UserUniversity, UserForm, FullUserCity, UserCity } from '../../types'
import { UnfilledStackNavigationType } from '../../types/navigation-types'
import type { UserData } from '../../types/user-types'

const emptyUniversity = {
  university: '',
  program: '',
}

export default (): JSX.Element => {
  const { replace } = useNavigation<UnfilledStackNavigationType<'UserForm'>>()
  const {
    saveUserData,
    uploadPicture,
    saveSelectedCities,
    saveSelectedUniversities,
  } = useFirebase()
  const [picture, showPictureOptions] = usePicturePicker()

  const { userFormState } = useTerbata()

  const [cities, setCities] = useState<FullUserCity[]>([])
  const [university, setUniversity] = useState<UserUniversity>(emptyUniversity)
  const [universities, setUniversities] = useState<UserUniversity[]>([])
  const [description, setDescription] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [showEmail, setShowEmail] = useState(false)

  const saveUserForm = () =>
    (picture
      ? uploadPicture({ picture: picture as Asset })
      : Promise.resolve(undefined)
    )
      .then(photoUrl => {
        const userCities: UserCity[] = cities.map(city => ({
          city: city.city,
          country: city.country,
          yearSince: city.yearSince,
          yearTo: city.yearTo ?? null,
        }))

        const formData: UserData = {
          isFilled: true,
          cities: userCities,
          universities,
          description,
          phoneNumber,
          photoUrl,
          showEmail,
        }
        return saveUserData({
          newUserData: formData,
        })
      })
      .then(() => saveSelectedCities({ newCities: cities }))
      .then(() =>
        saveSelectedUniversities({
          // @ts-ignore
          newUniversities: universities.map(uni => ({
            Country: uni.country,
            University: uni.university,
            Website: null,
          })),
        }),
      )
      .then(() => {
        replace('UserFormSuccess')
      })
      .catch(() => {
        showAlert('Neizdevās saglabāt lietotāja datus')
      })

  const onAddCity = (city: FullUserCity) => {
    setCities([...cities, city])
  }

  const onDeleteCity = (index: number) => {
    setCities(cities.filter((city, i) => i !== index))
  }

  const onChangeUniversity = (uni: UserUniversity) => {
    setUniversity(uni)
  }

  const onAddUniversity = (uni: UserUniversity) => {
    setUniversities([...universities, uni])
    setUniversity(emptyUniversity)
  }

  const onDeleteUniversity = (index: number) => {
    setUniversities(universities.filter((uni, i) => i !== index))
  }

  const forms = {
    [UserForm.CityForm]: (
      <CityForm cityCount={cities.length} onAddCity={onAddCity} />
    ),
    [UserForm.CityOverview]: (
      <CityOverview
        cities={cities}
        universities={universities}
        onDeleteCity={onDeleteCity}
      />
    ),
    [UserForm.UniversityForm1]: (
      <UniversityForm1
        university={university}
        universities={universities}
        onChangeUniversity={onChangeUniversity}
      />
    ),
    [UserForm.UniversityForm2]: (
      <UniversityForm2
        university={university}
        onAddUniversity={onAddUniversity}
      />
    ),
    [UserForm.UniversityOverview]: (
      <UniversityOverview
        universities={universities}
        onDeleteUniversity={onDeleteUniversity}
      />
    ),
    [UserForm.FinalForm]: (
      <FinalForm
        picture={picture as Asset}
        description={description}
        phoneNumber={phoneNumber}
        showEmail={showEmail}
        showPictureOptions={showPictureOptions}
        onChangeDescription={setDescription}
        onChangePhoneNumber={setPhoneNumber}
        onChangeShowEmail={setShowEmail}
        onSubmitForm={saveUserForm}
      />
    ),
  }

  const getForm = () => forms[userFormState]

  return (
    <ScreenWrapper>
      <View style={useSafeAreaPaddedStyle(headerOptions)}>
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          style={appStyles.fullSize}>
          <Header />
          <Separator height={30} />
          <View style={styles.formContainer}>
            <View style={styles.headerContainer}>
              <Text style={styles.titleText}>
                Ievadi papildus informāciju par sevi
              </Text>
            </View>
            <Separator height={30} />
            {getForm()}
          </View>
        </KeyboardAvoidingView>
      </View>
    </ScreenWrapper>
  )
}

const styles = StyleSheet.create({
  scrollContentContainer: {
    flexGrow: 1,
    paddingBottom: 30,
  },
  formContainer: {
    ...appStyles.fullSize,
    paddingHorizontal: 20,
    height: '100%',
  },
  headerContainer: {
    ...appStyles.row,
    justifyContent: 'center',
  },
  titleText: {
    fontSize: 20,
    fontWeight: '500',
  },
})
