import React, { useState } from 'react'
import {
  View,
  Text,
  Linking,
  Platform,
  TextInput,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  KeyboardAvoidingView,
} from 'react-native'

import { useNavigation } from '@react-navigation/native'

import {
  Button,
  Header,
  SvgIcon,
  CheckBox,
  showAlert,
  Separator,
  ScreenWrapper,
  ErrorMessage,
} from '../../components'
import useSafeAreaPaddedStyle, {
  headerOptions,
} from '../../hooks/useSafeAreaPaddedStyle'
import { useFirebase } from '../../providers/FirebaseProvider'
import appStyles from '../../styles/app-styles'
import Colors from '../../styles/Colors'
import { UnauthorizedStackNavigationType } from '../../types/navigation-types'

export default (): JSX.Element => {
  const { replace, goBack } =
    useNavigation<UnauthorizedStackNavigationType<'Register'>>()
  const { register } = useFirebase()

  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [isRepeatPasswordVisible, setIsRepeatPasswordVisible] = useState(false)

  const [name, setName] = useState('')
  const [surname, setSurname] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted] = useState(false)

  const [error, setError] = useState('')

  const showError = (errorMsg: string) => {
    if (Platform.OS !== 'web') {
      showAlert(errorMsg)
    }
    setError(errorMsg)
  }

  const registerUser = () => {
    if (!name || !surname || !email || !password || !repeatPassword) {
      showError('Lūdzu, aizpildiet visus laukus')
      return
    }
    if (
      !/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(password)
    ) {
      showError(
        'Parolei jābūt vismaz 8 simbolus garai, un jāsatur vismaz vienu lielo burtu, mazo burtu, ciparu un speciālo simbolu',
      )
      return
    }
    if (password !== repeatPassword) {
      showError('Paroles nesakrīt')
      return
    }
    if (!isPrivacyPolicyAccepted) {
      showError('Lūdzu, piekrītiet privātuma politikai')
      return
    }
    register({ email, password, name, surname }).catch(err => {
      switch (err.code) {
        case 'auth/email-already-exists':
          showError('E-pasts jau ir reģistrēts')
          return
        case 'auth/invalid-email':
          showError('Nederīgs e-pasts')
          return
      }
      showError('Neizdevās reģistrēties')
    })
  }

  return (
    <ScreenWrapper>
      <View style={useSafeAreaPaddedStyle(headerOptions)}>
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          style={appStyles.fullSize}>
          <ScrollView
            keyboardShouldPersistTaps="handled"
            alwaysBounceVertical={false}>
            <Header onGoBack={goBack} />
            <Separator height={50} />
            <View style={styles.headerContainer}>
              <Text style={styles.titleText}>Reģistrējies</Text>
            </View>
            <Separator height={50} />
            <View style={[appStyles.form, styles.formContainer]}>
              <TextInput
                value={name}
                placeholder="Vārds"
                autoCapitalize="words"
                autoComplete="given-name"
                placeholderTextColor={Colors.black}
                style={styles.textInput}
                onChangeText={setName}
              />
              <Separator height={20} />
              <TextInput
                value={surname}
                placeholder="Uzvārds"
                autoCapitalize="words"
                autoComplete="family-name"
                placeholderTextColor={Colors.black}
                style={styles.textInput}
                onChangeText={setSurname}
              />
              <Separator height={20} />
              <TextInput
                value={email}
                placeholder="E-pasts"
                inputMode="email"
                autoComplete="email"
                autoCapitalize="none"
                placeholderTextColor={Colors.black}
                style={styles.textInput}
                onChangeText={setEmail}
              />
              <Separator height={20} />
              <View>
                <TextInput
                  value={password}
                  placeholder="Parole"
                  textContentType="newPassword"
                  autoCapitalize="none"
                  autoComplete="new-password"
                  secureTextEntry={!isPasswordVisible}
                  placeholderTextColor={Colors.black}
                  style={styles.textInput}
                  onChangeText={setPassword}
                />
                <TouchableOpacity
                  hitSlop={10}
                  style={styles.eyeIcon}
                  onPress={() => setIsPasswordVisible(prev => !prev)}>
                  <SvgIcon
                    name="eye"
                    height={25}
                    width={30}
                    color={Colors.black}
                  />
                </TouchableOpacity>
              </View>
              <Separator height={20} />
              <View>
                <TextInput
                  value={repeatPassword}
                  placeholder="Parole atkārtoti"
                  autoCapitalize="none"
                  secureTextEntry={!isRepeatPasswordVisible}
                  placeholderTextColor={Colors.black}
                  style={styles.textInput}
                  onChangeText={setRepeatPassword}
                />
                <TouchableOpacity
                  hitSlop={10}
                  style={styles.eyeIcon}
                  onPress={() => setIsRepeatPasswordVisible(prev => !prev)}>
                  <SvgIcon
                    name="eye"
                    height={25}
                    width={30}
                    color={Colors.black}
                  />
                </TouchableOpacity>
              </View>
              <Separator height={30} />
              <View style={styles.privacyPolicyContainer}>
                <CheckBox
                  value={isPrivacyPolicyAccepted}
                  onValueChange={() =>
                    setIsPrivacyPolicyAccepted(prev => !prev)
                  }
                />
                <Separator width={10} />
                <Text>Esmu iepazinies un piekrītu </Text>
                <Text
                  style={styles.privacyPolicyLink}
                  onPress={() =>
                    Linking.openURL('https://www.terbata.lv/privātuma-politika')
                  }>
                  privātuma politikai
                </Text>
              </View>
              <Separator height={30} />
              <ErrorMessage
                containerStyle={styles.errorMessage}
                error={error}
              />
              <Separator height={30} />
              <Button text="Reģistrēties" onPress={registerUser} />
              <Separator height={50} />
              <View style={styles.loginContainer}>
                <Text>Esi jau reģistrējies?</Text>
                <Separator height={10} />
                <Button
                  outlined
                  text="Ienākt"
                  onPress={() => replace('Login')}
                />
                <Separator height={10} />
              </View>
            </View>
          </ScrollView>
        </KeyboardAvoidingView>
      </View>
    </ScreenWrapper>
  )
}

const styles = StyleSheet.create({
  headerContainer: {
    ...appStyles.row,
    justifyContent: 'center',
  },
  titleText: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  formContainer: {
    paddingHorizontal: 20,
  },
  textInput: {
    height: 40,
    borderRadius: 20,
    paddingHorizontal: 15,
    backgroundColor: Colors.gray,
  },
  eyeIcon: {
    top: 7,
    right: 10,
    position: 'absolute',
  },
  privacyPolicyContainer: {
    ...appStyles.row,
    alignSelf: 'center',
    alignItems: 'center',
  },
  privacyPolicyLink: {
    color: Colors.action,
  },
  loginContainer: {
    alignItems: 'center',
  },
  errorMessage: {
    margin: 'auto',
  },
})
