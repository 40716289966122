import React, { useState } from 'react'
import { StyleSheet, Text, TextInput, View } from 'react-native'

import { Asset } from 'react-native-image-picker'

import {
  Button,
  CheckBox,
  EditableList,
  Header,
  ProfileImage,
  Separator,
  showDestructiveAlert,
} from '../../../components'
import useSafeAreaPaddedStyle from '../../../hooks/useSafeAreaPaddedStyle'
import { useStudyData } from '../../../hooks/useStudyData'
import { useTerbata } from '../../../providers'
import appStyles from '../../../styles/app-styles'
import Colors from '../../../styles/Colors'
import { ProfileEditForm, UserCity, UserUniversity } from '../../../types'

const listItemFactory = (
  getStudyLevel: (studyLevelKey?: string | undefined) => string,
  getStudyField: (getStudyFieldKey?: string | undefined) => string,
) => {
  return (item: UserUniversity) => (
    <View>
      <Text>{`${item.university} (${item.startDate}-${item.endDate})`}</Text>
      <Text>{getStudyField(item.field)}</Text>
      <Text>{`${getStudyLevel(item.level)} - ${item.program}`}</Text>
    </View>
  )
}

const CityListItem = (item: UserCity) => (
  <Text>{`${item.city}, ${item.country} (${item.yearSince}-${
    item.yearTo ?? 'šobrīd'
  })`}</Text>
)
interface MainFormProps {
  name?: string
  surname?: string
  cities?: UserCity[]
  universities?: UserUniversity[]
  description?: string
  phoneNumber?: string
  email: string
  showEmail: boolean
  picture?: string | Asset
  setName: React.Dispatch<React.SetStateAction<string | undefined>>
  setSurname: React.Dispatch<React.SetStateAction<string | undefined>>
  setDescription: React.Dispatch<React.SetStateAction<string | undefined>>
  setCities: React.Dispatch<React.SetStateAction<UserCity[]>>
  setUniversities: React.Dispatch<React.SetStateAction<UserUniversity[]>>
  setPhoneNumber: React.Dispatch<React.SetStateAction<string | undefined>>
  setShowEmail: React.Dispatch<React.SetStateAction<boolean>>
  showPictureOptions: () => void

  onSave: () => void
  onGoBack: () => void
  onDeleteUser: () => void
}

export const MainForm: React.FC<MainFormProps> = ({
  name,
  surname,
  cities = [],
  universities = [],
  description,
  phoneNumber,
  email,
  showEmail,
  picture,
  setName,
  setSurname,
  setDescription,
  setCities,
  setUniversities,
  setPhoneNumber,
  setShowEmail,
  showPictureOptions,
  onSave,
  onGoBack,
  onDeleteUser,
}) => {
  const { getStudyLevel, getStudyField } = useStudyData()
  const { setProfileEditFormState } = useTerbata()

  const [height, setHeight] = useState(120)

  const UniversityListItem = listItemFactory(getStudyLevel, getStudyField)

  const onRemoveCity = (index: number) => {
    setCities(cities.filter((_, i) => i !== index))
  }

  const onRemoveUniversity = (index: number) => {
    setUniversities(universities.filter((_, i) => i !== index))
  }

  return (
    <View>
      <Header
        rightActions={[
          {
            icon: 'save',
            onPress: onSave,
          },
        ]}
        onGoBack={onGoBack}
      />
      <Separator height={20} />
      <ProfileImage
        uri={(picture as Asset)?.uri ?? (picture as string)}
        dimensions={100}
        onEditPress={showPictureOptions}
      />
      <Separator height={15} />
      <View style={styles.formContainer}>
        <Text style={appStyles.textInputLabel}>Vārds:</Text>
        <TextInput
          value={name}
          placeholder="Vārds"
          autoCapitalize="words"
          autoComplete="given-name"
          placeholderTextColor={Colors.description}
          style={styles.textInput}
          onChangeText={setName}
        />
        <Separator height={15} />
        <Text style={appStyles.textInputLabel}>Uzvārds:</Text>
        <TextInput
          value={surname}
          placeholder="Uzvārds"
          autoCapitalize="words"
          autoComplete="family-name"
          placeholderTextColor={Colors.description}
          style={styles.textInput}
          onChangeText={setSurname}
        />
        <Separator height={50} />
        <Text style={appStyles.textInputLabel}>Pilsētas:</Text>
        <Separator height={15} />
        <EditableList
          enforceNonEmpty
          items={cities
            .sort((a, b) => Number(a.yearSince) - Number(b.yearSince))
            .map((city, i) => ({ key: i, data: city }))}
          ItemComponent={CityListItem}
          onRemove={onRemoveCity}
        />
        <Separator height={15} />
        <Button
          text="Pievienot pilsētu"
          outlined={true}
          onPress={() => setProfileEditFormState(ProfileEditForm.CityForm)}
        />
        <Separator height={50} />
        <Text style={appStyles.textInputLabel}>Universitātes:</Text>
        <EditableList
          enforceNonEmpty
          items={universities
            .sort((a, b) => Number(a.startDate) - Number(b.startDate))
            .map((university, i) => ({ key: i, data: university }))}
          ItemComponent={UniversityListItem}
          onRemove={onRemoveUniversity}
        />
        <Separator height={20} />
        <Button
          text="Pievienot augstskolu"
          outlined={true}
          onPress={() =>
            setProfileEditFormState(ProfileEditForm.UniversityForm)
          }
        />
        <Separator height={50} />
        <Text style={appStyles.textInputLabel}>Apraksts:</Text>
        <TextInput
          multiline
          scrollEnabled={false}
          value={description}
          placeholder="Kas labs padomā?"
          placeholderTextColor={Colors.description}
          style={[styles.textInput, styles.multilineTextInput, { height }]}
          onChangeText={setDescription}
          onContentSizeChange={e => setHeight(e.nativeEvent.contentSize.height)}
        />
        <Separator height={15} />
        <Text style={appStyles.textInputLabel}>Telefona numurs:</Text>
        <TextInput
          value={phoneNumber}
          placeholder="+123 12345678"
          autoComplete="tel"
          placeholderTextColor={Colors.description}
          style={styles.textInput}
          onChangeText={setPhoneNumber}
        />
        <Separator height={50} />
        <Text style={appStyles.textInputLabel}>
          Ātrāki saziņai, lūdzu, norādi ka Tavs e-pasts būtu redzams arī citiem
          tērbatiešiem
        </Text>
        <View style={styles.showEmailContainer}>
          <Text>{email}</Text>
          <Separator width={10} />
          <CheckBox
            value={showEmail}
            onValueChange={() => setShowEmail(!showEmail)}
          />
          <Text style={[appStyles.textInputLabel, styles.showEmailText]}>
            Redzams
          </Text>
        </View>
        <Separator height={50} />
        <Text
          style={useSafeAreaPaddedStyle(
            { insetBottom: true },
            styles.deleteAccountText,
          )}>
          Ja vēlies dzēst savu kontu, spied{' '}
          <Text
            style={styles.deleteAccountLink}
            onPress={() =>
              showDestructiveAlert(
                'Vai tiešām vēlies dzēst savu kontu?',
                'Visi Jūsu ievadītie dati tiks neatgriezeniski dzēsti.',
                onDeleteUser,
                'Dzēst',
              )
            }>
            šeit
          </Text>
        </Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  picture: {
    height: 100,
    width: 100,
    borderRadius: 50,
  },
  pictureContainer: {
    alignSelf: 'center',
  },
  formContainer: {
    ...appStyles.form,
    paddingHorizontal: 20,
  },
  textInput: {
    height: 40,
    borderRadius: 20,
    paddingHorizontal: 15,
    backgroundColor: Colors.gray,
  },
  dropDownInput: {
    height: 50,
    paddingHorizontal: 15,
    backgroundColor: Colors.gray,
    borderRadius: 20,
    borderWidth: 0,
  },
  pickerEmpty: {
    alignSelf: 'center',
    paddingVertical: 12,
  },
  showEmailContainer: {
    paddingLeft: 10,
    ...appStyles.inlineContainer,
  },
  showEmailText: {
    paddingBottom: 0,
  },
  multilineTextInput: {
    minHeight: 40,
    paddingTop: 16,
    paddingHorizontal: 20,
    textAlignVertical: 'top',
  },
  deleteAccountText: {
    paddingLeft: 10,
    marginBottom: 20,
  },
  deleteAccountLink: {
    textDecorationLine: 'underline',
  },
})
