import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  ReactElement,
  Dispatch,
  SetStateAction,
} from 'react'

import { ProfileEditForm, UserForm } from '../types'

export type TerbataContextType = {
  userFormState: UserForm
  profileEditFormState: ProfileEditForm
  setUserFormState: Dispatch<SetStateAction<UserForm>>
  setProfileEditFormState: Dispatch<SetStateAction<ProfileEditForm>>
}

const TerbataContext = createContext<TerbataContextType>({
  userFormState: UserForm.CityForm,
  profileEditFormState: ProfileEditForm.Main,
  setUserFormState: () => {},
  setProfileEditFormState: () => {},
})

type TerbataProviderProps = {
  children: ReactNode
}

export const TerbataProvider = ({
  children,
}: TerbataProviderProps): ReactElement => {
  const [userFormState, setUserFormState] = useState(UserForm.CityForm)
  const [profileEditFormState, setProfileEditFormState] = useState(
    ProfileEditForm.Main,
  )

  return (
    <TerbataContext.Provider
      value={{
        userFormState,
        profileEditFormState,
        setUserFormState,
        setProfileEditFormState,
      }}>
      {children}
    </TerbataContext.Provider>
  )
}

export function useTerbata() {
  return useContext(TerbataContext)
}
