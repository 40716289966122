import React from 'react'
import { useState } from 'react'
import { Pressable, StyleSheet, Text, View } from 'react-native'

import { useNavigation } from '@react-navigation/native'

import { ProfileImage } from '../../../components'
import { useStudyData } from '../../../hooks/useStudyData'
import appStyles from '../../../styles/app-styles'
import Colors from '../../../styles/Colors'
import type { MainStackNavigationType } from '../../../types/navigation-types'
import type { UserData } from '../../../types/user-types'

interface PersonCardProps {
  person: UserData
}

export const PersonCard: React.FC<PersonCardProps> = ({ person }) => {
  const { navigate } = useNavigation<MainStackNavigationType<'Home'>>()
  const { getStudyField } = useStudyData()
  const [hover, setHover] = useState(false)

  const onGoToItem = () => {
    person.id &&
      navigate('Profile', {
        userId: person.id,
      })
  }

  return (
    <Pressable
      style={styles.personCard}
      onPress={onGoToItem}
      onHoverIn={() => setHover(true)}
      onHoverOut={() => setHover(false)}>
      <View style={[styles.imageSectionBackground]}>
        <View style={[styles.sectionHover, hover && styles.hover]}>
          <ProfileImage dimensions={150} uri={person.photoUrl} />
        </View>
      </View>
      <View style={[styles.textSectionBackground]}>
        <View style={[styles.sectionHover, hover && styles.hover]}>
          <Text
            style={[
              styles.text,
              styles.name,
            ]}>{`${person.name} ${person.surname}`}</Text>
          <Text style={styles.text} numberOfLines={1}>
            {getStudyField(person.universities?.[0]?.field)}
          </Text>
          <Text style={styles.text}>{person.cities?.[0]?.city}</Text>
        </View>
      </View>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  personCard: {
    borderRadius: 8,
    width: 300,
    height: 350,
    overflow: 'hidden',
    backgroundColor: Colors.cardImgBg,
    ...appStyles.lightShadow,
  },
  imageSectionBackground: {
    width: '100%',
    height: 230,
    backgroundColor: Colors.cardImgBg,
  },
  textSectionBackground: {
    width: '100%',
    height: 120,
    backgroundColor: Colors.cardTextBg,
    fontSize: 30,
  },
  sectionHover: {
    height: '100%',
    width: '100%',
    ...appStyles.center,
  },
  name: {
    fontWeight: '600',
  },
  text: {
    fontSize: 18,
    color: Colors.cardText,
  },
  hover: {
    backgroundColor: Colors.shadow,
  },
})
