import React, { useState, useEffect } from 'react'
import { StyleSheet, View, Text, Platform } from 'react-native'

import DropDownPicker from 'react-native-dropdown-picker'

import { FormPaginator } from './FormPaginator'
import { ErrorMessage, Separator } from '../../../components'
import { useFirebase, useTerbata } from '../../../providers'
import appStyles from '../../../styles/app-styles'
import Colors from '../../../styles/Colors'
import { UserUniversity, UserForm, UniversityData } from '../../../types'

interface UniversityForm1Props {
  university: UserUniversity
  universities: UserUniversity[]
  onChangeUniversity: (university: UserUniversity) => void
}

type InputType = 'country' | 'university' | 'studyLevel'

const dropdownMode = Platform.OS === 'web' ? undefined : 'MODAL'

export const UniversityForm1: React.FC<UniversityForm1Props> = ({
  university,
  universities,
  onChangeUniversity,
}) => {
  const [countries, setCountries] = useState<string[]>([])
  const [apiUniversities, setApiUniversities] = useState<UniversityData[]>()
  const { studyLevels, getCountries, getCountryUniversities } = useFirebase()
  const { setUserFormState } = useTerbata()

  const [country, setCountry] = useState<string | undefined>(university.country)
  const [universityName, setUniversityName] = useState<string | undefined>(
    university.university,
  )
  const [studyLevel, setStudyLevel] = useState<string | undefined>(
    university.level,
  )

  const [countriesOpen, setCountriesOpen] = useState(false)
  const [universitiesOpen, setUniversitiesOpen] = useState(false)
  const [studyLevelOpen, setStudyLevelOpen] = useState(false)

  const [errors, setErrors] = useState<InputType[]>([])

  useEffect(() => {
    getCountries().then(setCountries)
  }, [getCountries])

  useEffect(() => {
    if (country) getCountryUniversities({ country }).then(setApiUniversities)
  }, [country, getCountryUniversities])

  const onChangeStudyLevel = (callBack: () => string | null) => {
    const response = callBack()
    const level = response === null ? undefined : response
    setErrors([])
    setStudyLevel(level)
  }

  const onNext = () => {
    const unfilledInputs: InputType[] = []
    if (!country) unfilledInputs.push('country')
    if (country && !universityName) unfilledInputs.push('university')
    if (!studyLevel) unfilledInputs.push('studyLevel')
    setErrors(unfilledInputs)
    if (unfilledInputs.length > 0) return
    onChangeUniversity({
      ...university,
      country,
      university: universityName,
      level: studyLevel,
    })
    setUserFormState(UserForm.UniversityForm2)
  }

  return (
    <>
      <View style={appStyles.form}>
        <Text style={appStyles.textInputLabel}>
          Ievadi informāciju par augstskolu, kurā esi studējis, studē vai plāno
          studēt:
        </Text>
        <Separator height={20} />
        <Text style={appStyles.textInputLabel}>
          Kurā valstī atrodas iestāde?
        </Text>
        <DropDownPicker
          searchable
          zIndex={3}
          zIndexInverse={4}
          // @ts-ignore
          items={countries.map(c => ({
            label: c,
            value: c,
          }))}
          style={[
            styles.input,
            errors.includes('country') && styles.inputError,
          ]}
          open={countriesOpen}
          // @ts-ignore
          value={country}
          listMode={dropdownMode}
          setValue={setCountry}
          setOpen={setCountriesOpen}
          placeholder="Izvēlies valsti"
          searchPlaceholder="Meklēt..."
          onOpen={() => {
            setUniversitiesOpen(false)
            setStudyLevelOpen(false)
          }}
          onPress={() => setErrors(c => c.filter(i => i !== 'country'))}
        />
        {apiUniversities && (
          <>
            <Separator height={20} />
            <Text style={appStyles.textInputLabel}>Iestādes nosaukums</Text>
            <DropDownPicker
              searchable
              zIndex={2}
              zIndexInverse={5}
              items={apiUniversities.map(u => ({
                label: u.University,
                value: u.University,
              }))}
              style={[
                styles.input,
                errors.includes('university') && styles.inputError,
              ]}
              open={universitiesOpen}
              // @ts-ignore
              value={universityName}
              listMode={dropdownMode}
              setValue={setUniversityName}
              setOpen={setUniversitiesOpen}
              placeholder="Izvēlies iestādi"
              searchPlaceholder="Meklēt..."
              onOpen={() => {
                setCountriesOpen(false)
                setStudyLevelOpen(false)
              }}
              onPress={() => setErrors(c => c.filter(i => i !== 'university'))}
            />
          </>
        )}
        <Separator height={20} />
        <Text style={appStyles.textInputLabel}>Studiju līmenis</Text>
        <DropDownPicker
          zIndex={1}
          zIndexInverse={6}
          // @ts-ignore
          value={studyLevel}
          style={[
            styles.input,
            errors.includes('studyLevel') && styles.inputError,
          ]}
          open={studyLevelOpen}
          listMode={dropdownMode}
          // @ts-ignore
          setValue={onChangeStudyLevel}
          setOpen={setStudyLevelOpen}
          placeholder="Studiju līmenis"
          items={studyLevels.map(level => ({
            label: level.data.name,
            value: level.id,
          }))}
          onOpen={() => {
            setCountriesOpen(false)
            setUniversitiesOpen(false)
          }}
        />
        <Separator height={30} />
        {errors.length > 0 && (
          <ErrorMessage
            error={'Lūdzu, aizpildi visus nepieciešamos ievadlaukus!'}
            containerStyle={styles.errorContainer}
          />
        )}
      </View>
      <Separator height={30} />
      <FormPaginator
        onBack={() =>
          setUserFormState(
            universities.length
              ? UserForm.UniversityOverview
              : UserForm.CityOverview,
          )
        }
        onNext={onNext}
      />
    </>
  )
}

const styles = StyleSheet.create({
  input: {
    height: 50,
    paddingHorizontal: 15,
    backgroundColor: Colors.gray,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: Colors.gray,
  },
  inputError: {
    borderColor: Colors.errorText,
  },
  errorContainer: {
    margin: 'auto',
  },
})
