import { mixinOpacity } from '../utils'

const screenBackground = '#F8F6F4'
const themeColorLight = '#f7f7f7'
const themeColorMedium = '#FFFFFF'
const themeColorDark = '#3d0670' // 065970 // 06701f

export default {
  white: '#FFFFFF',
  lightGray: '#DDDDDD',
  gray: '#CCCCCC',
  darkGray: '#AAAAAA',
  black: '#000000',

  screenBackground: screenBackground,

  action: themeColorDark,
  actionHover: mixinOpacity(themeColorDark, 0.7, '#000000'),
  actionPressed: mixinOpacity(themeColorDark, 0.6, '#000000'),
  actionDisabled: mixinOpacity(themeColorDark, 0.7, '#FFFFFF'),
  actionText: '#FFFFFF',

  cardImgBg: themeColorMedium,
  cardTextBg: themeColorDark,
  cardText: 'white',

  errorText: 'red',

  inputBackground: 'white',

  text: '#000000',
  description: 'gray',

  shadow: 'rgba(0, 0, 0, 0.2)',
  highlight: 'rgba(255, 255, 255, 0.2)',
} as const
