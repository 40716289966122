import React, { ReactElement, useState } from 'react'
import { FlatList, Pressable, StyleSheet, View } from 'react-native'

import { SvgIcon } from './SvgIcon'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'

interface XProps {
  callback: () => void
}

const X: React.FC<XProps> = ({ callback }) => {
  const [deleteHovered, setDeleteHovered] = useState(false)

  return (
    <Pressable
      style={deleteHovered ? styles.deleteItemHover : styles.deleteItem}
      onPress={callback}
      onHoverIn={() => setDeleteHovered(true)}
      onHoverOut={() => setDeleteHovered(false)}>
      <SvgIcon name="x" color={deleteHovered ? Colors.white : Colors.action} />
    </Pressable>
  )
}

interface ListItem<T> {
  key: number
  data: T
}

interface EditableListProps<T> {
  items: ListItem<T>[]
  enforceNonEmpty?: boolean
  ItemComponent: (item: T) => ReactElement
  onRemove?: (index: number) => void
}

export const EditableList = <T extends any>({
  items,
  enforceNonEmpty = false,
  ItemComponent,
  onRemove,
}: EditableListProps<T>) => {
  const showRemove = !(enforceNonEmpty && items.length < 2)

  return (
    <FlatList
      data={items}
      style={styles.flatList}
      renderItem={({ item, index }) => (
        <View key={index} style={styles.listItem}>
          {ItemComponent(item.data)}
          <View>
            {onRemove && showRemove && <X callback={() => onRemove(index)} />}
          </View>
        </View>
      )}
    />
  )
}

const styles = StyleSheet.create({
  flatList: {
    maxHeight: 210,
  },
  listItem: {
    ...appStyles.inlineContainer,
    justifyContent: 'space-between',
    padding: 20,
    borderBottomWidth: 1,
    borderBlockColor: Colors.lightGray,
  },
  deleteItem: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.action,
    backgroundColor: 'transparent',
  },
  deleteItemHover: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.action,
    backgroundColor: Colors.action,
  },
})
