import React from 'react'
import { View, ActivityIndicator } from 'react-native'

import { ScreenWrapper } from '../../components'
import useSafeAreaPaddedStyle, {
  headerOptions,
} from '../../hooks/useSafeAreaPaddedStyle'
import appStyles from '../../styles/app-styles'

export const Loading: React.FC = () => {
  return (
    <ScreenWrapper>
      <View style={[useSafeAreaPaddedStyle(headerOptions)]}>
        <ActivityIndicator style={appStyles.fullSize} size="large" />
      </View>
    </ScreenWrapper>
  )
}
