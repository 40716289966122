import React from 'react'
import { View, StyleSheet } from 'react-native'

import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'

interface ScreenWrapperProps {
  limitWidth?: boolean
  children?: React.ReactNode | React.ReactNode[]
}

export const ScreenWrapper: React.FC<ScreenWrapperProps> = ({
  limitWidth = true,
  children,
}) => (
  <View style={styles.background}>
    <View style={[limitWidth && styles.maxWidth, appStyles.lightShadow]}>
      {children}
    </View>
  </View>
)

const styles = StyleSheet.create({
  background: {
    ...appStyles.fullSize,
    backgroundColor: Colors.lightGray,
  },
  maxWidth: {
    ...appStyles.fullSize,
    width: '100%',
    maxWidth: 1200,
    alignSelf: 'center',
    backgroundColor: Colors.screenBackground,
  },
})
