import React, { useEffect } from 'react'
import { Image, SafeAreaView, StyleSheet, Text } from 'react-native'

import { Button, ScreenWrapper, Separator, showAlert } from '../../components'
import { useFirebase } from '../../providers/FirebaseProvider'

export default (): JSX.Element => {
  const { user, sendVerificationEmail, logout, refreshCurrentUser } =
    useFirebase()

  useEffect(() => {
    const interval = setInterval(async () => {
      await refreshCurrentUser().catch(e => console.error(e))
    }, 5000)
    return () => clearInterval(interval)
  }, [refreshCurrentUser, user])

  return (
    <ScreenWrapper>
      <SafeAreaView style={styles.container}>
        <Image style={styles.logo} source={require('../../assets/logo.png')} />
        <Separator height={50} />
        <Text style={styles.subtitleText}>
          Lūdzu apstiprini savu e-pasta adresi,
        </Text>
        <Text style={styles.subtitleText}>izmantojot linku, ko nosūtījām</Text>
        <Text style={styles.subtitleText}>
          uz Tevis norādīto e-pasta adresi
        </Text>
        <Separator height={50} />
        <Button
          text={'Nosūtīt vēlreiz'}
          onPress={() =>
            sendVerificationEmail()
              .then(() => {
                showAlert('E-pasts nosūtīts')
              })
              .catch(error => {
                console.warn(error)
                showAlert('Neizdevās nosūtīt')
              })
          }
        />
        <Separator height={20} />
        <Button outlined text="Iziet" onPress={logout} />
      </SafeAreaView>
    </ScreenWrapper>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    width: 250,
    height: 250,
  },
  titleText: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  subtitleText: {
    fontSize: 16,
    fontWeight: 'normal',
  },
})
