import React, { useState, useEffect } from 'react'
import { Text, View, StyleSheet, Platform } from 'react-native'

import DropDownPicker from 'react-native-dropdown-picker'

import { FormPaginator } from './FormPaginator'
import { ErrorMessage, Separator } from '../../../components'
import { useFirebase, useTerbata } from '../../../providers'
import appStyles from '../../../styles/app-styles'
import Colors from '../../../styles/Colors'
import { CityData, FullUserCity, UserForm } from '../../../types'
import { getYearList } from '../../../utils'

interface CityFormProps {
  cityCount: number
  onAddCity: (city: FullUserCity) => void
}

type InputType = 'country' | 'city' | 'yearSince'

export const CityForm: React.FC<CityFormProps> = ({ cityCount, onAddCity }) => {
  const { getCountries, getCountryCities } = useFirebase()

  const [countries, setCountries] = useState<string[]>([])
  const [cities, setCities] = useState<CityData[]>()
  const [yearListSince] = useState(getYearList())
  const [yearListTo, setYearListTo] = useState(getYearList())

  const [countriesOpen, setCountriesOpen] = useState(false)
  const [citiesOpen, setCitiesOpen] = useState(false)
  const [yearSinceOpen, setYearSinceOpen] = useState(false)
  const [yearToOpen, setYearToOpen] = useState(false)

  const [country, setCountry] = useState<string>()
  const [city, setCity] = useState<number>()
  const [yearSince, setYearSince] = useState<number>()
  const [yearTo, setYearTo] = useState<number>()

  const [errors, setErrors] = useState<InputType[]>([])

  useEffect(() => {
    getCountries().then(setCountries)
  }, [getCountries])

  useEffect(() => {
    if (country) getCountryCities({ country }).then(setCities)
  }, [country, getCountryCities])

  const { setUserFormState } = useTerbata()

  const dropdownMode = Platform.OS === 'web' ? undefined : 'MODAL'

  const onSubmit = () => {
    const unfilledInputs: InputType[] = []
    if (!country) unfilledInputs.push('country')
    if (country && (city === undefined || !cities?.[city]))
      unfilledInputs.push('city')
    if (!yearSince) unfilledInputs.push('yearSince')
    setErrors(unfilledInputs)
    if (unfilledInputs.length > 0) return
    // @ts-ignore
    onAddCity({ ...cities[city], yearSince, yearTo })
    setUserFormState(UserForm.CityOverview)
  }

  useEffect(() => {
    if (yearSince) {
      const list = getYearList(yearSince)
      setYearListTo(list)
      if (list.map(item => item.value).includes(yearSince)) {
        setYearTo(undefined)
      }
    }
  }, [yearSince])

  return (
    <>
      <View style={appStyles.form}>
        <Text style={appStyles.textInputLabel}>
          Kurā valstī atrodies/ atradies?
        </Text>
        <DropDownPicker
          searchable
          zIndex={4}
          // @ts-ignore
          items={countries.map(c => ({
            label: c,
            value: c,
          }))}
          zIndexInverse={5}
          style={[
            styles.input,
            errors.includes('country') && styles.inputError,
          ]}
          open={countriesOpen}
          // @ts-ignore
          value={country}
          listMode={dropdownMode}
          setValue={setCountry}
          setOpen={setCountriesOpen}
          placeholder="Izvēlies valsti"
          searchPlaceholder="Meklēt..."
          onOpen={() => {
            setYearToOpen(false)
            setYearSinceOpen(false)
            setCitiesOpen(false)
          }}
          onPress={() => setErrors(c => c.filter(i => i !== 'country'))}
        />
        {cities && (
          <>
            <Separator height={20} />
            <Text style={appStyles.textInputLabel}>
              Kurā pilsētā atrodies/ atradies?
            </Text>
            <DropDownPicker
              searchable
              zIndex={3}
              // @ts-ignore
              items={cities.map((c, index) => ({
                label: c.city,
                value: index,
              }))}
              zIndexInverse={6}
              style={[
                styles.input,
                errors.includes('city') && styles.inputError,
              ]}
              open={citiesOpen}
              // @ts-ignore
              value={city}
              listMode={dropdownMode}
              setValue={setCity}
              setOpen={setCitiesOpen}
              placeholder="Izvēlies pilsētu"
              searchPlaceholder="Meklēt..."
              onOpen={() => {
                setYearToOpen(false)
                setYearSinceOpen(false)
                setCountriesOpen(false)
              }}
              onPress={() => setErrors(c => c.filter(i => i !== 'city'))}
            />
          </>
        )}
        <Separator height={20} />
        <Text style={appStyles.textInputLabel}>
          Sākot ar kuru gadu esi šajā vietā vai plāno būt?
        </Text>
        <DropDownPicker
          zIndex={2}
          zIndexInverse={7}
          // @ts-ignore
          items={yearListSince}
          style={[
            styles.input,
            errors.includes('yearSince') && styles.inputError,
          ]}
          open={yearSinceOpen}
          // @ts-ignore
          value={yearSince}
          listMode={dropdownMode}
          setOpen={setYearSinceOpen}
          placeholder="Izvēlies gadu"
          // @ts-ignore
          setValue={setYearSince}
          onOpen={() => {
            setYearToOpen(false)
            setCountriesOpen(false)
            setCitiesOpen(false)
          }}
          onPress={() => setErrors(c => c.filter(i => i !== 'yearSince'))}
        />
        <Separator height={20} />
        <Text style={appStyles.textInputLabel}>
          Kurā gadā no šīs vietas plāno aizbraukt vai aizbrauci?
        </Text>
        <DropDownPicker
          autoScroll={true}
          zIndex={1}
          zIndexInverse={8}
          // @ts-ignore
          items={yearListTo}
          open={yearToOpen}
          // @ts-ignore
          value={yearTo}
          style={styles.input}
          listMode={dropdownMode}
          setOpen={setYearToOpen}
          placeholder="Izvēlies gadu"
          // @ts-ignore
          setValue={setYearTo}
          onOpen={() => {
            setYearSinceOpen(false)
            setCountriesOpen(false)
            setCitiesOpen(false)
          }}
        />
        <Separator height={30} />
        {errors.length > 0 && (
          <ErrorMessage
            error={'Lūdzu, aizpildi visus nepieciešamos ievadlaukus!'}
            containerStyle={styles.errorContainer}
          />
        )}
      </View>
      <Separator height={30} />

      <FormPaginator
        hasBack={cityCount ? true : false}
        onBack={() => setUserFormState(UserForm.CityOverview)}
        onNext={onSubmit}
      />
    </>
  )
}

const styles = StyleSheet.create({
  input: {
    height: 50,
    paddingHorizontal: 15,
    backgroundColor: Colors.gray,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: Colors.gray,
  },
  inputError: {
    borderColor: Colors.errorText,
  },
  errorContainer: {
    margin: 'auto',
  },
})
