import React, { useMemo } from 'react'
import { useEffect, useState } from 'react'
import { StyleSheet, Text, View, ScrollView } from 'react-native'

import {
  RouteProp,
  useIsFocused,
  useNavigation,
  useRoute,
} from '@react-navigation/native'

import {
  Header,
  Separator,
  ProfileImage,
  ScreenWrapper,
  showDestructiveAlert,
} from '../../components'
import useSafeAreaPaddedStyle, {
  headerOptions,
} from '../../hooks/useSafeAreaPaddedStyle'
import { useStudyData } from '../../hooks/useStudyData'
import { useFirebase } from '../../providers/FirebaseProvider'
import appStyles from '../../styles/app-styles'
import Colors from '../../styles/Colors'
import {
  MainStackParamsType,
  MainStackNavigationType,
} from '../../types/navigation-types'
import type { UserData } from '../../types/user-types'

export default (): JSX.Element => {
  const { params } = useRoute<RouteProp<MainStackParamsType, 'Profile'>>()
  const { navigate } = useNavigation<MainStackNavigationType<'Profile'>>()
  const { getUserById, logout, user } = useFirebase()
  const { getStudyLevel, getStudyField } = useStudyData()
  const [fetchedUser, setFetchedUser] = useState<UserData>()
  const isFocused = useIsFocused()

  const canEdit = useMemo(() => {
    if (!user) return false
    return user.uid === (params?.userId ?? user.uid)
  }, [user, params?.userId])

  useEffect(() => {
    getUserById({ id: params?.userId ?? user?.uid ?? '' }).then(setFetchedUser)
  }, [params, getUserById, isFocused, user?.uid])

  const sortedCities = useMemo(() => {
    if (!fetchedUser?.cities) return
    return fetchedUser.cities
      .sort((a, b) => Number(a.yearSince) - Number(b.yearSince))
      .map((city, i) => ({ key: i, city }))
  }, [fetchedUser?.cities])

  const sortedUniversities = useMemo(() => {
    if (!fetchedUser?.universities) return
    return fetchedUser.universities
      .sort((a, b) => Number(a.startDate) - Number(b.startDate))
      .map((uni, i) => ({ key: i, uni }))
  }, [fetchedUser?.universities])

  return (
    <ScreenWrapper>
      <View style={useSafeAreaPaddedStyle(headerOptions)}>
        <Header
          rightActions={
            canEdit
              ? [
                  {
                    icon: 'edit',
                    onPress: () => navigate('ProfileEdit'),
                  },
                  {
                    icon: 'logout',
                    onPress: () =>
                      showDestructiveAlert(
                        'Vai tiešām vēlaties iziet?',
                        '',
                        logout,
                        'Iziet',
                      ),
                  },
                ]
              : []
          }
          onGoBack={() => navigate('Home')}
        />
        <View style={styles.container}>
          <Separator height={20} />
          <ScrollView
            keyboardShouldPersistTaps="handled"
            showsVerticalScrollIndicator={false}>
            <View style={appStyles.horizontalCenter}>
              <ProfileImage uri={fetchedUser?.photoUrl} dimensions={200} />
              <Separator height={20} />
              <Text style={styles.heading}>
                {fetchedUser?.name} {fetchedUser?.surname}
              </Text>
            </View>
            <Separator height={20} />
            <Separator isLine />
            <Separator height={20} />
            <Text style={styles.fieldName}>Pilsētas:</Text>
            {sortedCities?.map(({ key, city }) => (
              <View key={key} style={styles.listItem}>
                <Text>{`${city.city}, ${city.country} (${city.yearSince}-${
                  city.yearTo ?? 'šobrīd'
                })`}</Text>
              </View>
            ))}
            <Separator height={20} />
            <Text style={styles.fieldName}>Universitātes:</Text>
            {sortedUniversities?.map(({ key, uni }) => (
              <View key={key} style={styles.listItem}>
                <Text>{`${uni.university} (${uni.startDate}-${uni.endDate})`}</Text>
                <Text>{getStudyField(uni.field)}</Text>
                <Text>{`${getStudyLevel(uni.level)} - ${uni.program}`}</Text>
              </View>
            ))}
            <Separator height={20} />
            <Text style={styles.fieldName}>Apraksts:</Text>
            <Text>{fetchedUser?.description}</Text>
            {fetchedUser?.showEmail && (
              <>
                <Separator height={20} />
                <Text style={styles.fieldName}>E-pasts:</Text>
                <Text>{fetchedUser?.email}</Text>
              </>
            )}
            {fetchedUser?.phoneNumber && (
              <>
                <Separator height={20} />
                <Text style={styles.fieldName}>Telefona numurs:</Text>
                <Text>{fetchedUser?.phoneNumber}</Text>
              </>
            )}
            <Separator height={40} />
          </ScrollView>
        </View>
      </View>
    </ScreenWrapper>
  )
}

const styles = StyleSheet.create({
  container: {
    ...appStyles.fullSize,
    ...appStyles.form,
    paddingHorizontal: 20,
  },
  heading: {
    fontSize: 24,
    fontWeight: '600',
  },
  listItem: {
    padding: 20,
    borderBottomWidth: 1,
    borderBlockColor: Colors.lightGray,
  },
  fieldName: {
    marginBottom: 10,
    fontWeight: 'bold',
  },
})
