import React, { useEffect, useState } from 'react'

import {
  NavigationContainer,
  createNavigationContainerRef,
} from '@react-navigation/native'

import MainStack from './MainStack'
import UnauthorizedStack from './UnauthorizedStack'
import UnfilledStack from './UnfilledStack'
import UnverifiedStack from './UnverifiedStack'
import { useFirebase } from '../providers/FirebaseProvider'
import { Error } from '../screens/error/Error'
import { Loading } from '../screens/loading/Loading'

const NavigationStack: React.FC = () => {
  const { isLoading, user, userData, getUserData } = useFirebase()
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    if (user && user.emailVerified) {
      getUserData().catch(() => setIsError(true))
    }
  }, [user, getUserData])

  if (isError) return <Error number={500} />
  if (isLoading) return <Loading />
  if (!user) return <UnauthorizedStack />
  if (!user.emailVerified) return <UnverifiedStack />
  if (!userData) return <Loading />
  if (userData && !userData.isFilled) return <UnfilledStack />
  return <MainStack />
}

export const Navigation: React.FC = () => {
  const navigationRef = createNavigationContainerRef()
  return (
    <NavigationContainer
      ref={navigationRef}
      documentTitle={{
        formatter: (options, route) =>
          `Tērbata - ${options?.title ?? route?.name}`,
      }}
      linking={{
        prefixes: ['https://terbata.vercel.app'],
        config: {
          screens: {
            Welcome: '/',
            Register: '/register',
            ForgotPassword: '/reset-password',
            Login: '/login',
            UserForm: '/userForm',
            UserFormSuccess: '/userFormSuccess',
            VerifyEmail: '/verify',
            Home: '/home',
            Profile: '/profile',
            ProfileEdit: '/profile/edit',
            Filter: '/home/filter',
          },
        },
      }}>
      <NavigationStack />
    </NavigationContainer>
  )
}
