import React, { useCallback, useEffect, useState } from 'react'
import {
  View,
  Text,
  FlatList,
  Keyboard,
  TextInput,
  StyleSheet,
  TouchableOpacity,
} from 'react-native'

import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'

import { PersonCard } from './components/PersonCard'
import { Header, ScreenWrapper, Separator, SvgIcon } from '../../components'
import useSafeAreaPaddedStyle, {
  headerOptions,
} from '../../hooks/useSafeAreaPaddedStyle'
import { useFirebase } from '../../providers/FirebaseProvider'
import appStyles from '../../styles/app-styles'
import Colors from '../../styles/Colors'
import {
  MainStackParamsType,
  MainStackNavigationType,
} from '../../types/navigation-types'
import { UserData } from '../../types/user-types'

export default (): JSX.Element => {
  const { params } = useRoute<RouteProp<MainStackParamsType, 'Home'>>()
  const { navigate } = useNavigation<MainStackNavigationType<'Home'>>()
  const { getUsersPage, getAllUsers } = useFirebase()
  const [users, setUsers] = useState<UserData[]>([])
  const [searchText, setSearchText] = useState<string>('')
  const [filteredUsers, setFilteredUsers] = useState<UserData[]>([])
  const [countriesFilter, setCountriesFilter] = useState<string[]>([])
  const [citiesFilter, setCitiesFilter] = useState<string[]>([])
  const [universitiesFilter, setUniversitiesFilter] = useState<string[]>([])
  const [levelsFilter, setLevelsFilter] = useState<string[]>([])
  const [fieldsFilter, setFieldsFilter] = useState<string[]>([])
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false)
  const [lastId, setLastId] = useState<any>()
  const [hasEnteredFilterMode, setHasEnteredFilterMode] =
    useState<boolean>(false)

  useEffect(() => {
    getUsersPage().then(res => {
      setFilteredUsers(res.users)
      setLastId(res.lastUser)
    })
  }, [getUsersPage])

  const loadMoreUsers = useCallback(() => {
    if (!lastId || hasEnteredFilterMode) return
    getUsersPage(lastId).then(res => {
      setFilteredUsers(prev => [...prev, ...res.users])
      setLastId(res.lastUser)
    })
  }, [getUsersPage, hasEnteredFilterMode, lastId])

  const filterUsers = useCallback(async () => {
    if (!hasEnteredFilterMode) {
      setHasEnteredFilterMode(true)
      await getAllUsers().then(setUsers)
    }

    let results = users
    if (searchText) {
      results = users.filter(u => {
        const fullName = `${u.name} ${u.surname}`
        const containsName = fullName
          .toLowerCase()
          .includes(searchText.toLowerCase())
        const containsCity = u.cities?.find(city =>
          city.city?.toLowerCase().includes(searchText.toLowerCase()),
        )
        return containsName || containsCity
      })
    }

    if (countriesFilter.length > 0) {
      results = results.filter(u => {
        if (!u.cities) {
          return false
        }
        return u.cities.find(city =>
          countriesFilter.includes(city.country ?? ''),
        )
      })
    }

    if (citiesFilter.length > 0) {
      results = results.filter(u => {
        if (!u.cities) {
          return false
        }
        return u.cities.find(city => citiesFilter.includes(city.city ?? ''))
      })
    }

    if (universitiesFilter.length > 0) {
      results = results.filter(u => {
        if (!u.universities) {
          return false
        }
        return u.universities.find(uni =>
          universitiesFilter.includes(uni.university ?? ''),
        )
      })
    }

    if (levelsFilter.length > 0) {
      results = results.filter(u => {
        if (!u.universities) {
          return false
        }
        return u.universities.find(uni =>
          levelsFilter.includes(uni.level ?? ''),
        )
      })
    }

    if (fieldsFilter.length > 0) {
      results = results.filter(u => {
        if (!u.universities) {
          return false
        }
        return u.universities.find(uni =>
          fieldsFilter.includes(uni.field ?? ''),
        )
      })
    }

    setFilteredUsers(results)
  }, [
    hasEnteredFilterMode,
    users,
    searchText,
    countriesFilter,
    citiesFilter,
    universitiesFilter,
    levelsFilter,
    fieldsFilter,
    getAllUsers,
  ])

  useEffect(() => {
    setLevelsFilter(params?.filterLevels ?? [])
    setFieldsFilter(params?.filterFields ?? [])
    setCountriesFilter(params?.filterCountries ?? [])
    setCitiesFilter(params?.filterCities ?? [])
    setUniversitiesFilter(params?.filterUniversities ?? [])
  }, [params])

  useEffect(() => {
    const hasFilters =
      levelsFilter.length > 0 ||
      fieldsFilter.length > 0 ||
      universitiesFilter.length > 0 ||
      countriesFilter.length > 0 ||
      citiesFilter.length > 0
    setFiltersApplied(hasFilters)
    if (hasFilters || params?.filtersReset) filterUsers()
  }, [
    levelsFilter,
    fieldsFilter,
    filterUsers,
    countriesFilter.length,
    citiesFilter.length,
    params?.filtersReset,
    universitiesFilter.length,
  ])

  return (
    <ScreenWrapper>
      <View style={useSafeAreaPaddedStyle(headerOptions)}>
        <Header
          rightActions={[
            {
              icon: 'person',
              onPress: () => navigate('Profile'),
            },
          ]}
        />
        <Separator height={10} />
        <View style={styles.form}>
          <View style={styles.searchInput}>
            <TextInput
              style={styles.textInput}
              placeholder="Ievadi vārdu, uzvārdu vai pilsētu"
              placeholderTextColor={Colors.black}
              value={searchText}
              onChangeText={setSearchText}
              onSubmitEditing={filterUsers}
            />
            <TouchableOpacity
              style={styles.searchButton}
              onPress={() => {
                Keyboard.dismiss()
                filterUsers()
              }}>
              <SvgIcon name="search" color={Colors.actionText} />
            </TouchableOpacity>
          </View>
          <View>
            <TouchableOpacity
              style={[
                styles.filter,
                filtersApplied ? styles.filtersApplied : styles.filtersEmpty,
              ]}
              onPress={() =>
                navigate('Filter', {
                  filterLevels: levelsFilter,
                  filterFields: fieldsFilter,
                  filterCountries: countriesFilter,
                  filterCities: citiesFilter,
                })
              }>
              <SvgIcon
                name="filter"
                color={filtersApplied ? Colors.actionText : Colors.action}
              />
            </TouchableOpacity>
          </View>
        </View>
        <Separator height={20} />
        {hasEnteredFilterMode && (
          <Text style={styles.resultCountText}>
            Meklēšanas rezultāti: {filteredUsers.length}
          </Text>
        )}
        <View style={styles.line}>
          <Separator isLine />
        </View>
        <Separator height={20} />
        <FlatList
          data={filteredUsers}
          style={styles.peopleList}
          keyboardShouldPersistTaps="handled"
          contentContainerStyle={useSafeAreaPaddedStyle(
            { insetBottom: true },
            styles.contentContainer,
          )}
          renderItem={({ item }) => <PersonCard person={item} />}
          keyExtractor={item => item.id ?? ''}
          // columnWrapperStyle={Platform.OS === 'web' && styles.columnWrapper}
          onEndReached={loadMoreUsers}
        />
      </View>
    </ScreenWrapper>
  )
}

const styles = StyleSheet.create({
  form: {
    ...appStyles.row,
    ...appStyles.form,
    gap: 10,
    padding: 10,
  },
  searchInput: {
    flex: 5,
    height: 40,
    borderRadius: 20,
    ...appStyles.row,
    ...appStyles.center,
    backgroundColor: Colors.inputBackground,
    paddingLeft: 10,
  },
  textInput: {
    flex: 1,
    height: 40,
    padding: 5,
  },
  searchButton: {
    width: 40,
    height: 40,
    ...appStyles.center,
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    backgroundColor: Colors.action,
    paddingRight: 3,
  },
  filter: {
    ...appStyles.center,
    width: 40,
    height: 40,
    flex: 1,
    borderRadius: 20,
    borderColor: Colors.action,
    borderWidth: 1,
  },
  filtersEmpty: {
    backgroundColor: Colors.white,
  },
  filtersApplied: {
    backgroundColor: Colors.action,
  },
  resultCountText: {
    textAlign: 'center',
    color: Colors.description,
    paddingHorizontal: 24,
    paddingVertical: 16,
  },
  line: {
    alignSelf: 'center',
    width: '90%',
  },
  peopleList: { height: 0 },
  contentContainer: {
    gap: 20,
    marginVertical: 10,
    paddingHorizontal: 24,
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  columnWrapper: {
    gap: 20,
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
})
