import React from 'react'

import { createStackNavigator } from '@react-navigation/stack'

import UserFormScreen from '../screens/user-form/UserFormScreen'
import UserFormSuccessScreen from '../screens/user-form/UserFormSuccessScreen'
import type { UnfilledStackParamsType } from '../types/navigation-types'

export default (): JSX.Element => {
  const UnfilledStack = createStackNavigator<UnfilledStackParamsType>()

  return (
    <UnfilledStack.Navigator
      initialRouteName="UserForm"
      screenOptions={{ headerShown: false }}>
      <UnfilledStack.Screen
        name="UserForm"
        component={UserFormScreen}
        options={{ title: 'Profila forma' }}
      />
      <UnfilledStack.Screen
        name="UserFormSuccess"
        component={UserFormSuccessScreen}
        options={{ title: 'Profils aizpildīts' }}
      />
    </UnfilledStack.Navigator>
  )
}
