import React from 'react'
import { View, StyleSheet, Text } from 'react-native'

import { FormPaginator } from './FormPaginator'
import { Button, EditableList, Separator } from '../../../components'
import { useTerbata } from '../../../providers'
import appStyles from '../../../styles/app-styles'
import Colors from '../../../styles/Colors'
import { UserUniversity, UserForm, FullUserCity } from '../../../types'

const ListItem = (item: FullUserCity) => (
  <Text>{`${item.city}, ${item.country} (${item.yearSince}-${
    item.yearTo ?? 'šobrīd'
  })`}</Text>
)

interface CityOverviewProps {
  cities: FullUserCity[]
  universities: UserUniversity[]
  onDeleteCity: (index: number) => void
}

export const CityOverview: React.FC<CityOverviewProps> = ({
  cities,
  universities,
  onDeleteCity,
}) => {
  const { setUserFormState } = useTerbata()

  return (
    <>
      <View style={appStyles.form}>
        <Text style={[appStyles.textInputLabel, styles.label]}>
          Norādi vismaz vienu pilsētu, kurā kādreiz esi dzīvojis:
        </Text>
        <Separator height={20} />
        <EditableList
          items={cities
            .sort((a, b) => Number(a.yearSince) - Number(b.yearSince))
            .map((city, i) => ({ key: i, data: city }))}
          ItemComponent={ListItem}
          onRemove={onDeleteCity}
        />
        <Separator height={20} />
        <Button
          text="Pievienot pilsētu"
          outlined={true}
          onPress={() => setUserFormState(UserForm.CityForm)}
        />
      </View>
      <Separator height={30} />
      <FormPaginator
        hasBack={false}
        nextDisabled={!cities.length}
        onNext={() =>
          setUserFormState(
            universities.length
              ? UserForm.UniversityOverview
              : UserForm.UniversityForm1,
          )
        }
      />
    </>
  )
}

const styles = StyleSheet.create({
  label: {
    margin: 'auto',
  },
  input: {
    height: 50,
    paddingHorizontal: 15,
    backgroundColor: Colors.gray,
    borderRadius: 20,
    borderWidth: 0,
  },
})
