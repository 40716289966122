import React, { useState } from 'react'
import {
  View,
  Text,
  Platform,
  TextInput,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  KeyboardAvoidingView,
} from 'react-native'

import { useNavigation } from '@react-navigation/native'

import {
  Header,
  Separator,
  Button,
  SvgIcon,
  showAlert,
  ScreenWrapper,
} from '../../components'
import useSafeAreaPaddedStyle, {
  headerOptions,
} from '../../hooks/useSafeAreaPaddedStyle'
import { useFirebase } from '../../providers/FirebaseProvider'
import appStyles from '../../styles/app-styles'
import Colors from '../../styles/Colors'
import { UnauthorizedStackNavigationType } from '../../types/navigation-types'

export default (): JSX.Element => {
  const { navigate, replace, goBack } =
    useNavigation<UnauthorizedStackNavigationType<'Login'>>()
  const { login } = useFirebase()

  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const loginUser = () => {
    if (!email || !password) {
      showAlert('Lūdzu, aizpildiet visus laukus')
      return
    }
    login({ email, password }).catch(() => {
      showAlert('Nepareizs e-pasts un/ vai parole')
    })
  }

  return (
    <ScreenWrapper>
      <View style={useSafeAreaPaddedStyle(headerOptions)}>
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          style={appStyles.fullSize}>
          <ScrollView
            keyboardShouldPersistTaps="handled"
            alwaysBounceVertical={false}>
            <Header onGoBack={goBack} />
            <Separator height={50} />
            <View style={styles.headerContainer}>
              <Text style={styles.titleText}>Ienāc</Text>
            </View>
            <Separator height={50} />
            <View style={[appStyles.form, styles.formContainer]}>
              <TextInput
                value={email}
                placeholder="E-pasts"
                inputMode="email"
                autoComplete="email"
                autoCapitalize="none"
                placeholderTextColor={Colors.black}
                style={styles.textInput}
                onChangeText={setEmail}
              />
              <Separator height={10} />
              <View>
                <TextInput
                  value={password}
                  placeholder="Parole"
                  textContentType="password"
                  autoCapitalize="none"
                  autoComplete="current-password"
                  secureTextEntry={!isPasswordVisible}
                  placeholderTextColor={Colors.black}
                  style={styles.textInput}
                  onChangeText={setPassword}
                />
                <TouchableOpacity
                  hitSlop={10}
                  style={styles.eyeIcon}
                  onPress={() => setIsPasswordVisible(prev => !prev)}>
                  <SvgIcon
                    name="eye"
                    height={25}
                    width={30}
                    color={Colors.black}
                  />
                </TouchableOpacity>
              </View>
            </View>
            <Separator height={30} />
            <Button text="Ienākt" onPress={loginUser} />
            <Separator height={50} />
            <View style={styles.loginContainer}>
              <Text onPress={() => navigate('ForgotPassword')}>
                Aizmirsi paroli?
              </Text>
              <Separator height={10} />
              <Button
                outlined
                text="Atjaunot paroli"
                onPress={() => navigate('ForgotPassword')}
              />
              <Separator height={30} />
              <Text>Neesi reģistrējies?</Text>
              <Separator height={10} />
              <Button
                outlined
                text="Reģistrēties"
                onPress={() => replace('Register')}
              />
            </View>
          </ScrollView>
        </KeyboardAvoidingView>
      </View>
    </ScreenWrapper>
  )
}

const styles = StyleSheet.create({
  headerContainer: {
    ...appStyles.row,
    justifyContent: 'center',
  },
  titleText: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  formContainer: {
    paddingHorizontal: 20,
  },
  textInput: {
    height: 40,
    borderRadius: 20,
    paddingHorizontal: 15,
    backgroundColor: Colors.gray,
  },
  eyeIcon: {
    top: 7,
    right: 10,
    position: 'absolute',
  },
  loginContainer: {
    alignItems: 'center',
  },
})
