import React from 'react'

import { createStackNavigator } from '@react-navigation/stack'

import FilterScreen from '../screens/filter/FilterScreen'
import HomeScreen from '../screens/home/HomeScreen'
import ProfileScreen from '../screens/profile/ProfileScreen'
import ProfileEditScreen from '../screens/profileEdit/ProfileEditScreen'
import UserFormSuccessScreen from '../screens/user-form/UserFormSuccessScreen'
import { MainStackParamsType } from '../types/navigation-types'

const MainStack = createStackNavigator<MainStackParamsType>()

export default (): JSX.Element => (
  <MainStack.Navigator
    initialRouteName="Home"
    screenOptions={{ headerShown: false }}>
    <MainStack.Screen
      name="Home"
      component={HomeScreen}
      options={{ title: 'Sākums' }}
    />
    <MainStack.Screen
      name="Filter"
      component={FilterScreen}
      options={{ title: 'Filtri' }}
    />
    <MainStack.Screen
      name="UserFormSuccess"
      component={UserFormSuccessScreen}
      options={{ title: 'Profils aizpildīts' }}
    />
    <MainStack.Screen
      name="Profile"
      component={ProfileScreen}
      options={{ title: 'Profils' }}
    />
    <MainStack.Screen
      name="ProfileEdit"
      component={ProfileEditScreen}
      options={{ title: 'Labot profilu' }}
    />
  </MainStack.Navigator>
)
