import React from 'react'
import { Image, SafeAreaView, StyleSheet, Text } from 'react-native'

import { useNavigation } from '@react-navigation/native'

import { Button, ScreenWrapper, Separator } from '../../components'
import { UnauthorizedStackNavigationType } from '../../types/navigation-types'

export default (): JSX.Element => {
  const { navigate } =
    useNavigation<UnauthorizedStackNavigationType<'Welcome'>>()

  return (
    <ScreenWrapper>
      <SafeAreaView style={styles.container}>
        <Image style={styles.logo} source={require('../../assets/logo.png')} />
        <Separator height={50} />
        <Text style={styles.titleText}>Tērbata</Text>
        <Separator height={50} />
        <Text style={styles.subtitleText}>
          Satiec ārzemēs studējošos latviešus
        </Text>
        <Separator height={50} />
        <Button text="Ienākt" onPress={() => navigate('Login')} />
        <Separator height={20} />
        <Button
          outlined
          text="Reģistrēties"
          onPress={() => navigate('Register')}
        />

        <Separator height={30} />

        <Button
          outlined
          text="Aizmirsu paroli"
          onPress={() => navigate('ForgotPassword')}
        />
      </SafeAreaView>
    </ScreenWrapper>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    width: 250,
    height: 250,
  },
  titleText: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  subtitleText: {
    fontSize: 16,
    fontWeight: 'normal',
  },
})
