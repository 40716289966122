import React from 'react'
import {
  StyleSheet,
  TouchableOpacity,
  TouchableOpacityProps,
} from 'react-native'

import { SvgIcon } from './SvgIcon'
import Colors from '../styles/Colors'

export const CheckBox: React.FC<
  Omit<TouchableOpacityProps, 'children'> & {
    value: boolean
    onValueChange: () => void
  }
> = ({ value, onValueChange }) => (
  <TouchableOpacity
    style={[styles.container, value && styles.checkedContainer]}
    onPress={onValueChange}>
    <SvgIcon
      name="check"
      //   size={14}
      color={Colors.white}
    />
  </TouchableOpacity>
)

const styles = StyleSheet.create({
  container: {
    width: 25,
    height: 25,
    borderWidth: 1,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: Colors.black,
    backgroundColor: Colors.white,
  },
  checkedContainer: {
    backgroundColor: Colors.action,
  },
})
