import React from 'react'
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native'

import { Separator } from './Separator'
import { SvgIcon, SvgName } from './SvgIcon'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'

type RightAction = {
  icon: SvgName
  onPress: () => void
}

export const Header: React.FC<{
  onGoBack?: () => void
  rightActions?: RightAction[]
}> = ({ onGoBack, rightActions }) => (
  <View style={styles.header}>
    <View style={appStyles.fullSize}>
      {onGoBack && (
        <TouchableOpacity hitSlop={10} onPress={onGoBack}>
          <SvgIcon
            name="chevron-left"
            height={30}
            width={30}
            color={Colors.black}
          />
        </TouchableOpacity>
      )}
    </View>
    <Image style={styles.logo} source={require('../assets/logo.png')} />
    <View style={styles.optionsContainer}>
      {rightActions?.map(({ icon, onPress }, index) => (
        <View key={index} style={appStyles.inlineContainer}>
          <Separator width={20} />
          <TouchableOpacity hitSlop={10} onPress={onPress}>
            <SvgIcon name={icon} height={30} width={30} color={Colors.black} />
          </TouchableOpacity>
        </View>
      ))}
    </View>
  </View>
)

const styles = StyleSheet.create({
  header: {
    ...appStyles.row,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  logo: {
    width: 80,
    height: 80,
  },
  optionsContainer: {
    ...appStyles.fullSize,
    justifyContent: 'flex-end',
    ...appStyles.inlineContainer,
  },
})
