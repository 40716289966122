import { useCallback, useEffect, useState } from 'react'

import imageCompression from 'browser-image-compression'
import { FirebaseApp, initializeApp } from 'firebase/app'
import type { Auth, User } from 'firebase/auth'
import {
  getAuth,
  deleteUser as deleteFirebaseUser,
  updateProfile,
  onAuthStateChanged,
  sendEmailVerification,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail as resetPassword,
} from 'firebase/auth'
import {
  doc,
  query,
  limit,
  where,
  setDoc,
  getDoc,
  getDocs,
  orderBy,
  deleteDoc,
  Firestore,
  updateDoc,
  collection,
  documentId,
  getFirestore,
  startAfter,
} from 'firebase/firestore'
import {
  ref,
  getStorage,
  uploadBytes,
  deleteObject,
  getDownloadURL,
  FirebaseStorage,
} from 'firebase/storage'

import actionCodeConfig from '../config/action-code-config'
import firebaseConfig from '../config/firebase-config'
import { FirebaseContextType } from '../providers/FirebaseProvider'
import { UniversityData } from '../types'
import { CityData, CountriesList, Country } from '../types/city-types'
import { StudyDataEntry } from '../types/study-data-types'
import type { UserData } from '../types/user-types'

const useFirebaseClient = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [app, setApp] = useState<FirebaseApp | null>(null)
  const [auth, setAuth] = useState<Auth | null>(null)
  const [firestore, setFirestore] = useState<Firestore | null>(null)
  const [storage, setStorage] = useState<FirebaseStorage | null>(null)
  const [user, setUser] = useState<User | null>(null)
  const [userData, setUserData] = useState<UserData | null>(null)
  const [studyLevels, setStudyLevels] = useState<StudyDataEntry[]>([])
  const [studyFields, setStudyFields] = useState<StudyDataEntry[]>([])
  const [selectedCities, setSelectedCities] = useState<CityData[]>([])
  const [selectedUniversities, setSelectedUniversities] = useState<
    UniversityData[]
  >([])

  useEffect(() => {
    setApp(initializeApp(firebaseConfig))
  }, [])

  useEffect(() => {
    if (app) setAuth(getAuth(app))
  }, [app])

  useEffect(() => {
    if (app) setFirestore(getFirestore(app))
  }, [app])

  useEffect(() => {
    if (app) setStorage(getStorage(app))
  }, [app])

  useEffect(() => {
    if (!auth) return
    setIsLoading(true)
    const subscriber = onAuthStateChanged(auth, u => {
      setUser(u)
      setIsLoading(false)
    })
    return subscriber
  }, [auth])

  const getStudyLevels = (firestr: Firestore) => {
    const levelsCollectionRef = collection(firestr, 'studyLevels')
    const levelsQuery = query(levelsCollectionRef, orderBy('index'))
    getDocs(levelsQuery)
      .then(levelsSnapshot => {
        setStudyLevels(
          levelsSnapshot.docs.map(
            d => ({ id: d.id, data: d.data() } as StudyDataEntry),
          ),
        )
      })
      .catch(console.error)
  }

  const getStudyFields = (firestr: Firestore) => {
    const fieledsCollectionRef = collection(firestr, 'studyFields')
    const fieldsQuery = query(fieledsCollectionRef, orderBy('index'))
    getDocs(fieldsQuery)
      .then(fieldsSnapshot => {
        setStudyFields(
          fieldsSnapshot.docs.map(
            d => ({ id: d.id, data: d.data() } as StudyDataEntry),
          ),
        )
      })
      .catch(console.error)
  }

  const getSelectedCities = (firestr: Firestore) => {
    const selectedCitiesRef = collection(firestr, 'selectedCities')
    const selectedCitiesQuery = query(selectedCitiesRef)
    getDocs(selectedCitiesQuery).then(selectedCitiesSnapshot => {
      setSelectedCities(
        selectedCitiesSnapshot.docs.map(d => d.data()) as CityData[],
      )
    })
  }

  const getSelectedUniversities = (firestr: Firestore) => {
    const selectedUniversitiesRef = collection(firestr, 'selectedUniversities')
    const selectedUniversitiesQuery = query(selectedUniversitiesRef)
    getDocs(selectedUniversitiesQuery).then(selectedUniversitiesSnapshot => {
      setSelectedUniversities(
        selectedUniversitiesSnapshot.docs.map(d =>
          d.data(),
        ) as UniversityData[],
      )
    })
  }

  useEffect(() => {
    if (!firestore) return
    getStudyLevels(firestore)
    getStudyFields(firestore)
    getSelectedCities(firestore)
    getSelectedUniversities(firestore)
  }, [firestore])

  const register: FirebaseContextType['register'] = useCallback(
    async ({ email, password, name, surname }) => {
      if (!auth || !firestore) return Promise.reject()
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      )
      await updateProfile(userCredential.user, {
        displayName: `${name} ${surname}`,
      })
      const docRef = doc(firestore, 'users', userCredential.user.uid)
      await setDoc(docRef, {
        name,
        surname,
        email,
        isFilled: false,
      })
      await sendEmailVerification(userCredential.user, actionCodeConfig)
    },
    [auth, firestore],
  )

  const sendVerificationEmail: FirebaseContextType['sendVerificationEmail'] =
    useCallback(async () => {
      if (!auth || !user) return Promise.reject()
      return sendEmailVerification(user, actionCodeConfig)
    }, [auth, user])

  const login: FirebaseContextType['login'] = useCallback(
    async ({ email, password }) => {
      if (!auth) return Promise.reject()
      await signInWithEmailAndPassword(auth, email, password)
    },
    [auth],
  )

  const logout: FirebaseContextType['logout'] = useCallback(async () => {
    if (!auth) return
    await auth.signOut()
  }, [auth])

  const refreshCurrentUser: FirebaseContextType['refreshCurrentUser'] =
    useCallback(async () => {
      if (!auth) return
      await auth.currentUser?.reload().catch(console.error)
      setUser({ ...auth.currentUser } as User)
    }, [auth])

  const getBlob = async (fileUri: string) => {
    const resp = await fetch(fileUri)
    return resp.blob()
  }

  const compressPicture = useCallback(async (uri: string) => {
    const options = {
      maxSizeMb: 1,
      maxWidthOrHeight: 1280,
    }
    const blob = await getBlob(uri ?? '')
    return imageCompression(blob, options)
  }, [])

  const uploadPicture: FirebaseContextType['uploadPicture'] = useCallback(
    async ({ picture }) => {
      if (!user || !storage || !picture.uri) return Promise.reject()
      const storageRef = ref(storage, `users/${user?.uid}/profilePicture`)
      const compressedPicture = await compressPicture(picture.uri)
      return uploadBytes(storageRef, compressedPicture).then(snapshot =>
        getDownloadURL(snapshot.ref),
      )
    },
    [user, storage, compressPicture],
  )

  const getUserData: FirebaseContextType['getUserData'] =
    useCallback(async () => {
      if (!user || !firestore) return Promise.reject()
      setIsLoading(true)
      const docRef = doc(firestore, 'users', user.uid)
      const docSnap = await getDoc(docRef).finally(() => setIsLoading(false))
      if (!docSnap.exists()) return Promise.reject()
      setUserData(docSnap.data() as UserData)
    }, [user, firestore])

  const deletePicture: FirebaseContextType['deletePicture'] =
    useCallback(async () => {
      if (!user || !storage) return Promise.reject()
      if (!userData?.photoUrl) return Promise.resolve()
      const storageRef = ref(storage, `users/${user?.uid}/profilePicture`)
      return deleteObject(storageRef)
    }, [user, storage, userData?.photoUrl])

  const getUserById: FirebaseContextType['getUserById'] = useCallback(
    async ({ id }) => {
      if (!user || !firestore) return Promise.reject()
      const docRef = doc(firestore, 'users', id)
      const docSnap = await getDoc(docRef)
      if (!docSnap.exists()) return Promise.reject()
      return { id, ...docSnap.data() } as UserData
    },
    [user, firestore],
  )

  const getUsersFirstPage = useCallback(async () => {
    if (!user || !firestore) return Promise.reject()
    const collectionRef = collection(firestore, 'users')
    const usersQuery = query(
      collectionRef,
      where(documentId(), '!=', user.uid),
      orderBy(documentId()),
      limit(12),
    )
    const usersSnapshot = await getDocs(usersQuery)
    const users = usersSnapshot.docs.map(d => ({ id: d.id, ...d.data() }))
    return {
      users: users as UserData[],
      lastUser: usersSnapshot.docs.at(-1),
    }
  }, [firestore, user])

  const getUsersPage: FirebaseContextType['getUsersPage'] = useCallback(
    async (lastUser?: any) => {
      if (!lastUser) return getUsersFirstPage()
      if (!user || !firestore) return Promise.reject()
      const collectionRef = collection(firestore, 'users')
      const usersQuery = query(
        collectionRef,
        where(documentId(), '!=', user.uid),
        orderBy(documentId()),
        startAfter(lastUser),
        limit(12),
      )
      const usersSnapshot = await getDocs(usersQuery)
      const users = usersSnapshot.docs.map(d => ({ id: d.id, ...d.data() }))
      return {
        users: users as UserData[],
        lastUser: usersSnapshot.docs.at(-1),
      }
    },
    [firestore, getUsersFirstPage, user],
  )

  const getAllUsers: FirebaseContextType['getAllUsers'] =
    useCallback(async () => {
      if (!user || !firestore) return Promise.reject()
      const collectionRef = collection(firestore, 'users')
      const usersQuery = query(
        collectionRef,
        where(documentId(), '!=', user.uid),
      )
      const usersSnapshot = await getDocs(usersQuery)
      const users = usersSnapshot.docs.map(d => ({ id: d.id, ...d.data() }))
      return users as UserData[]
    }, [firestore, user])

  const saveUserData: FirebaseContextType['saveUserData'] = useCallback(
    async ({ newUserData }) => {
      if (!firestore || !user) return Promise.reject()
      setUserData(newUserData)

      const data = Object.entries(newUserData)
        .filter(([_, value]) => value !== undefined)
        .reduce((obj, [key, value]) => {
          //@ts-ignore
          obj[key] = value
          return obj
        }, {})

      const docRef = doc(firestore, 'users', user.uid)
      await updateDoc(docRef, data)
    },
    [firestore, user],
  )

  const deleteUser: FirebaseContextType['deleteUser'] =
    useCallback(async () => {
      if (!user || !firestore || !storage) return Promise.reject()
      const docRef = doc(firestore, 'users', user.uid)
      deletePicture()
        .then(() => deleteDoc(docRef))
        .then(() => deleteFirebaseUser(user))
    }, [firestore, storage, user, deletePicture])

  const saveSelectedCities: FirebaseContextType['saveSelectedCities'] =
    useCallback(
      async ({ newCities }) => {
        if (!firestore) return Promise.reject()
        const collectionRef = collection(firestore, 'selectedCities')
        newCities.forEach(async c => {
          const newCity = {
            city: c.city,
            country: c.country,
            longitude: c.longitude,
            latitude: c.latitude,
          }
          if (
            !selectedCities.find(
              city =>
                city.city === newCity.city && city.country === newCity.country,
            )
          ) {
            await setDoc(doc(collectionRef, newCity.city), newCity)
            setSelectedCities(cities => [...cities, newCity])
          }
        })
      },
      [firestore, selectedCities, setSelectedCities],
    )

  const saveSelectedUniversities: FirebaseContextType['saveSelectedUniversities'] =
    useCallback(
      async ({ newUniversities }) => {
        if (!firestore) return Promise.reject()
        const collectionRef = collection(firestore, 'selectedUniversities')
        newUniversities.forEach(async newUni => {
          if (
            !selectedUniversities.find(
              uni =>
                uni.Country === newUni.Country &&
                uni.University === newUni.University,
            )
          ) {
            await setDoc(doc(collectionRef, newUni.University), newUni)
            setSelectedUniversities(unis => [...unis, newUni])
          }
        })
      },
      [firestore, selectedUniversities, setSelectedUniversities],
    )

  const sendPasswordResetEmail: FirebaseContextType['sendPasswordResetEmail'] =
    useCallback(
      async (email: string) => {
        if (!auth) return Promise.reject()
        return await resetPassword(auth, email, actionCodeConfig)
      },
      [auth],
    )

  const getCountries: FirebaseContextType['getCountries'] =
    useCallback(async () => {
      if (!firestore) return Promise.reject()
      const docRef = doc(firestore, 'countries', 'list')
      const docSnap = await getDoc(docRef)
      if (!docSnap.exists()) return Promise.reject()
      return (docSnap.data() as CountriesList).all
    }, [firestore])

  const getCountryCities: FirebaseContextType['getCountryCities'] = useCallback(
    async ({ country }) => {
      if (!firestore) return Promise.reject()
      const docRef = doc(firestore, 'CityList4', country)
      const docSnap = await getDoc(docRef)
      if (!docSnap.exists()) return Promise.reject()
      return (docSnap.data() as Country).cities.map(city => ({
        country,
        city: city.city,
        city_ascii: city.city_ascii,
        latitude: city.y,
        longitude: city.x,
      }))
    },
    [firestore],
  )

  const getCountryUniversities: FirebaseContextType['getCountryUniversities'] =
    useCallback(
      async ({ country }) => {
        if (!firestore) return Promise.reject()
        const collectionRef = collection(firestore, 'universities')
        const universitiesQuery = query(
          collectionRef,
          where('Country', '==', country),
        )
        const universities = await getDocs(universitiesQuery)
        if (!universities) return Promise.reject()
        return universities.docs.map(uni => uni.data() as UniversityData)
      },
      [firestore],
    )

  return {
    isLoading,
    user,
    userData,
    studyLevels,
    studyFields,
    selectedCities,
    selectedUniversities,
    register,
    sendVerificationEmail,
    login,
    logout,
    refreshCurrentUser,
    uploadPicture,
    getUserData,
    deletePicture,
    getUserById,
    saveUserData,
    getUsersPage,
    getAllUsers,
    deleteUser,
    saveSelectedCities,
    saveSelectedUniversities,
    sendPasswordResetEmail,
    getCountries,
    getCountryCities,
    getCountryUniversities,
  }
}

export { useFirebaseClient }
