import React from 'react'
import { View, Text, StyleSheet } from 'react-native'

import { ScreenWrapper } from '../../components'
import useSafeAreaPaddedStyle, {
  headerOptions,
} from '../../hooks/useSafeAreaPaddedStyle'
import appStyles from '../../styles/app-styles'

interface ErrorProps {
  number: number
}

export const Error: React.FC<ErrorProps> = ({ number }) => {
  return (
    <ScreenWrapper>
      <View
        style={[
          useSafeAreaPaddedStyle(headerOptions),
          appStyles.fullSize,
          appStyles.center,
        ]}>
        <Text style={styles.text}>Error {number}</Text>
      </View>
    </ScreenWrapper>
  )
}

const styles = StyleSheet.create({
  text: {
    fontSize: 20,
    fontWeight: '500',
  },
})
